import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/index",
    name: "Home",
    component: Home,  // 首页
    meta: {
      keepAlive: true,
      showbottom: true,
    }
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"), // 全部分类页
    meta: {
      keepAlive: true,
      showbottom: true
    }
  },
  {
    path: "/list",
    name: "List",
    component: () => import("../views/List.vue"), // 分类列表页
    meta: {
      keepAlive: true,
      showbottom: true
    }
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("../views/Search.vue") // 搜索页面
  },
  {
    path: "/details",
    name: "Details",
    component: () => import("../views/Details.vue"), // 产品详情页
    meta: {
      keepAlive: true,
      showbottom: true
    }
  },
  {
    path: "/cart",
    name: "Cart",
    component: () => import("../views/Cart.vue"), // 购物车页
    meta: {
      keepAlive: true,
      showbottom: true
    }
  },
  {
    path: "/myhome",
    name: "Myhome",
    component: () => import("../views/Myhome.vue"), // 个人中心页
    meta: {
      keepAlive: true,
      showbottom: true
    }
  },
  {
    path: "/setinfo",
    name: "SetInfo",
    component: () => import("../views/SetInfo.vue") // 修改个人信息页
  },
  {
    path: "/addressList",
    name: "AddressList",
    component: () => import("../views/AddressList.vue") // 收货地址页
  },
  {
    path: "/addressEdit",
    name: "AddressEdit",
    component: () => import("../views/AddressEdit.vue") // 修改收货地址页
  },
  {
    path: "/distributor",
    name: "Distributor",
    component: () => import("../views/Distributor.vue"), // 分销商首页
    meta: {
      keepAlive: true,
      showbottom: true
    }
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue") // 登陆页面
  },
  {
    path: "/forgetpwd",
    name: "Forgetpwd",
    component: () => import("../views/Forgetpwd.vue") // 登陆页面
  },
  {
    path: "/rigsit",
    name: "Rigsit",
    component: () => import("../views/Rigsit.vue") // 登陆页面
  },
  {
    path: "/Seeqrderqcode",
    name: "SeeOrderQcode",
    component: () => import("../views/SeeOrderQcode.vue") // 查单二维码
  },
  {
    path: "/payment",
    name: "Payment",
    component: () => import("../views/Payment.vue"), // 订单预览
    meta: {
      keepAlive: true,
      showbottom: true
    }
  },
  {
    path: "/v1payment",
    name: "V1Payment",
    component: () => import("../views/V1Payment.vue"), // 订单预览-新
    meta: {
      keepAlive: true,
      showbottom: false
    }
  },
  {
    path: "/checkpayment",
    name: "CheckPayment",
    component: () => import("../views/CheckPayment.vue"), // 订单预览
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/orderlist",
    name: "OrderList",
    component: () => import("../views/OrderList.vue"), // 订单列表
    meta: {
      keepAlive: true,
      showbottom: true
    }
  },
  {
    path: "/orderdetails",
    name: "OrderDetails",
    component: () => import("../views/OrderDetails.vue"), // 订单详情
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/orderrefund",
    name: "OrderRefund",
    component: () => import("../views/OrderRefund.vue"), // 申请退款页面
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/income",
    name: "Income",
    component: () => import("../views/Income.vue"), // 我的收益
    meta: {
      keepAlive: true,
      showbottom: true
    }
  },
  {
    path: "/myteam",
    name: "MyTeam",
    component: () => import("../views/MyTeam.vue"), // 我的团队
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/teamorder",
    name: "TeamOrder",
    component: () => import("../views/TeamOrder.vue"), // 我的团队订单 
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/paysuccess",
    name: "PaySuccess",
    component: () => import("../views/PaySuccess.vue"), // 支付成功
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/changemoble1",
    name: "ChangeMoble1",
    component: () => import("../views/ChangeMoble1.vue"), // 验证原手机号
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/changemoble2",
    name: "ChangeMoble2",
    component: () => import("../views/ChangeMoble2.vue"), // 验证新手机号
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/comissiontixian",
    name: "ComissionTixian",
    component: () => import("../views/ComissionTixian.vue"), // 佣金提现
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/favorite",
    name: "Favorite",
    component: () => import("../views/Favorite.vue"), // 收藏夹
    meta: {
      keepAlive: true,
      showbottom: true
    }
  },
  {
    path: "/tixianresult",
    name: "TixianResult",
    component: () => import("../views/TixianResult.vue"), // 提现结果
    meta: {
      keepAlive: true,
      showbottom: true
    }
  },
  {
    path: "/ordertwoList",
    name: "OrderTwoList",
    component: () => import("../views/OrderTwoList.vue"), // 售后订单和驳回订单
    meta: {
      keepAlive: true,
      showbottom: true
    }
  },
  {
    path: "/material",
    name: "Material",
    component: () => import("../views/Material.vue"), // 素材中心
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/seedetail",
    name: "SeeDetail",
    component: () => import("../views/SeeDetail.vue"), // 提现详情
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/promotecode",
    name: "PromoteCode",
    component: () => import("../views/PromoteCode.vue"), // 会员推广码
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/gonghuoapply1",
    name: "GonghuoApply1",
    component: () => import("../views/gonghuo/Apply1.vue"), // 供货申请页面
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/gonghuoapply2",
    name: "GonghuoApply2",
    component: () => import("../views/gonghuo/Apply2.vue"), // 供货申请入住
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/gonghuoapply3",
    name: "GonghuoApply3",
    component: () => import("../views/gonghuo/Apply3.vue"), // 供货申请入住
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/gonghuoapply4",
    name: "GonghuoApply4",
    component: () => import("../views/gonghuo/Apply4.vue"), // 供货申请入住
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/gonghuoapply5",
    name: "GonghuoApply5",
    component: () => import("../views/gonghuo/Apply5.vue"), // 供货申请入住
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/gonghuoapply6",
    name: "GonghuoApply6",
    component: () => import("../views/gonghuo/Apply6.vue"), // 供货申请入住
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/fenxiaoapply1",
    name: "FenxiaoApply1",
    component: () => import("../views/fenxiao/Apply1.vue"), // 分销商申请开店
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/fenxiaoapply2",
    name: "FenxiaoApply2",
    component: () => import("../views/fenxiao/Apply2.vue"), // 分销商申请开店
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/merchcode",
    name: "MerchCode",
    component: () => import("../views/MerchCode.vue"), // 商户二维码
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/ordersearch",
    name: "OrderSearch",
    component: () => import("../views/OrderSearch.vue"), // 订单搜索
    meta: {
      keepAlive: true,
      showbottom: true
    }
  },
  {
    path: "/gonghuoqianyue",
    name: "GonghuoQianyue",
    component: () => import("../views/gonghuo/GonghuoQianyue.vue"), // 供货签约
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/merchtishi",
    name: "merchTishi",
    component: () => import("../views/gonghuo/merchTishi.vue"), // 供货签约
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/cooperation",
    name: "Cooperation",
    component: () => import("../views/Cooperation.vue"), // 商务合作
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/wallet",
    name: "Wallet",
    component: () => import("../views/Wallet.vue"), // 钱包页面
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/recharge",
    name: "Recharge",
    component: () => import("../views/Recharge.vue"), // 钱包页面
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/rechargelist",
    name: "RechargeList",
    component: () => import("../views/RechargeList.vue"), // 余额变动表
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/rechargepic",
    name: "RechargePic",
    component: () => import("../views/RechargePic.vue"), // 充值图片上传页面
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/rechargehistory",
    name: "RechargeHistory",
    component: () => import("../views/RechargeHistory.vue"), // 充值列表页
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/complaint",
    name: "Complaint",
    component: () => import("../views/Complaint.vue"), // 充值列表页
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/complaintlist",
    name: "ComplaintList",
    component: () => import("../views/ComplaintList.vue"), // 建议与反馈列表页
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/complaintdetail",
    name: "ComplaintDetail",
    component: () => import("../views/ComplaintDetail.vue"), // 建议与反馈列表页
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/messagelist",
    name: "MessageList",
    component: () => import("../views/message/MessageList.vue"), // 消息列表页
    meta: {
      keepAlive: true,
      showbottom: true
    }
  },
  {
    path: "/messagedetails",
    name: "MessageDetails",
    component: () => import("../views/message/MessageDetails.vue"), // 消息详情页
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/applyservice",
    name: "ApplyService",
    component: () => import("../views/gonghuo/applyservice.vue"), // 消息详情页
    meta: {
      keepAlive: true,
      showbottom: true
    }
  },
  {
    path: "/videolist",
    name: "VideoList",
    component: () => import("../views/video/list.vue"), // 消息详情页
    meta: {
      keepAlive: true,
      showbottom: true
    }
  },
  {
    path: "/videoDetail",
    name: "videoDetail",
    component: () => import("../views/video/detail.vue"), // 视频详情
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/addvideo",
    name: "VideoAdd",
    component: () => import("../views/video/add.vue"), // 消息详情页
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/myvideo",
    name: "Myvideo",
    component: () => import("../views/video/Myvideo.vue"), // 我的视频页
    meta: {
      keepAlive: true,
      showbottom: true
    }
  },
  {
    path: "/orderservice",
    name: "OrderService",
    component: () => import("../views/gonghuo/OrderService.vue"), // 供货商 订单首页页面
    meta: {
      keepAlive: true,
      showbottom: true
    }
  },
  {
    path: "/closePage",
    name: "closePage",
    component: () => import("../views/closePage.vue"), // 已关闭页面
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/addAccount",
    name: "addAccount",
    component: () => import("../views/fenxiao/addAccount.vue"), // 联盟店添加子账号
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/accountlist",
    name: "AccountList",
    component: () => import("../views/fenxiao/AccountList.vue"), // 联盟店添加子账号
    meta: {
      keepAlive: true,
      showbottom: true
    }
  },
  {
    path: "/newList",
    name: "newList",
    component: () => import("../views/newList.vue"), // 每日上新页面
    meta: {
      keepAlive: true,
      showbottom: true
    }
  },
  {
    path: "/yinsi",
    name: "yinSi",
    component: () => import("../views/fenxiao/yinsi.vue"), // 隐私政策
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/xieyi",
    name: "xieYi",
    component: () => import("../views/fenxiao/xieyi.vue"), // 用户协议
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/test",
    name: "Test",
    component: () => import("../views/test/test.vue"), // 测试专用
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/serviceDetail",
    name: "serviceDetail",
    component: () => import("../views/serviceDetail.vue"), // 售后详情
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/yunlist",
    name: "YunList",
    component: () => import("../views/yunlist.vue"), // 云店专供列表
    meta: {
      keepAlive: true,
      showbottom: true
    }
  },
  {
    path: "/refundorderone",
    name: "RefundOrderOne",
    component: () => import("../views/RefundOrderOne.vue"), // 选择售后类型
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/download",
    name: "Download",
    component: () => import("../views/download.vue"), // 下载地址模板
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/addmoreaddress",
    name: "addMoreAddress",
    component: () => import("../views/addmoreaddress.vue"), // 下载地址模板
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/v1paymentmore",
    name: "V1PaymentMore",
    component: () => import("../views/V1PaymentMore.vue"), // 多地址下单
    meta: {
      keepAlive: true,
      showbottom: true
    }
  },
  {
    path: "/dudaoBrandList",
    name: "dudaoBrandList",
    component: () => import("../views/dudaoBrandList.vue"), // 督导商品品牌列表
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/brandlist",
    name: "BrandList",
    component: () => import("../views/brandlist.vue"), // 品牌列表
    meta: {
      keepAlive: true,
      showbottom: true
    }
  },
  {
    path: "/activitylist",
    name: "activityList",
    component: () => import("../views/activity/activitylist.vue"), // 品牌列表
    meta: {
      keepAlive: true,
      showbottom: true
    }
  },
  {
    path: "/activitydetail",
    name: "activityDetail",
    component: () => import("../views/activity/activitydetail.vue"), // 品牌列表
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/minituikuan",
    name: "miniTuikuan",
    component: () => import("../views/minituikuan.vue"), // 小额退款
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/refundhistory",
    name: "refundHistory",
    component: () => import("../views/refundhistory.vue"), // 退款历史
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/hexiaoorder",
    name: "hexiaoOrder",
    component: () => import("../views/activity/hexiaoorder.vue"), // 品牌列表
    meta: {
      keepAlive: true,
      showbottom: true
    }
  },
  {
    path: "/zengpinList",
    name: "ZengPinList",
    component: () => import("../views/recharge/zengpinList.vue"), // 赠品列表
    meta: {
      keepAlive: true,
      showbottom: true
    }
  },
  {
    path: "/zengpinDetail",
    name: "ZengPinDetail",
    component: () => import("../views/recharge/zengpinDetail.vue"), // 赠品列表
    meta: {
      keepAlive: true,
      showbottom: true
    }
  },
  {
    path: "/commissionMoney",
    name: "CommissionMoney",
    component: () => import("../views/commissionMoney.vue"), // 预计收益列表
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/shop_list",
    name: "ShopList",
    component: () => import("../views/shop_list.vue"), // 预计收益列表
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/category_brand",
    name: "CategoryBrand",
    component: () => import("../views/category_brand.vue"), // 品牌下面的分类
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/get_open",
    name: "getOpen",
    component: () => import("../views/getopen.vue"), // 品牌下面的分类
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/hebing",
    name: "Hebing",
    component: () => import("../views/hebing.vue"), // 账号列表
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/account_list",
    name: "AccountTwoList",
    component: () => import("../views/account_list.vue"), // 账号列表
    meta: {
      keepAlive: false,
      showbottom: false
    }
  },
  {
    path: "/robot_start",
    name: "RobotStart",
    component: () => import("../views/robot/start.vue"), // 机器人启动页
    meta: {
      keepAlive: false,
      showbottom: false
    }
  },
  {
    path: "/robot_qrcode",
    name: "RobotQRcode",
    component: () => import("../views/robot/qrcode.vue"), // 机器人扫码
    meta: {
      keepAlive: false,
      showbottom: false
    }
  },
  {
    path: "/ghsdetail",
    name: "Ghsdetail",
    component: () => import("../views/Ghsdetail.vue"), // 营业执照信息
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/ghslist",
    name: "Ghslist",
    component: () => import("../views/Ghslist.vue"), // 营业商品信息
    meta: {
      keepAlive: false,
      showbottom: true
    }
  },
  {
    path: "/ghs_login",
    name: "GhsLogin",
    component: () => import("../views/gonghuo/ghs_login.vue"), // 供货商登陆
    meta: {
      keepAlive: false,
      showbottom: false
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition  //游览器返回记住位置
    } else {
      return { x: 0, y: 0 } //滚动到顶
    }
  }
});

export default router;
