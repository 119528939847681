import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    domain: "https://api.midbest.cn/",  // 默认请求地址 https://api.midbest.cn/web   http://192.168.1.50/web http://mytest.com/web
    domain2: "https://dk.midbest.cn/api/v1",  // 默认请求地址 https://api.midbest.cn/web   http://192.168.1.50/web
    domain3: "https://dk.midbest.cn/",  // 默认url
    banben: 2, // 页面缓存
    wxappid: "wxc465682b705715e5",
    showkefu: 0,
    merchname: "",
  },
  mutations: {},
  actions: {},
  modules: {}
});
