<template>
  <div id="app" ref="app">
    <div id="nav" v-if="!$route.meta.showbottom ? false : true">
      <van-tabbar v-model="active">
        <van-tabbar-item>
          <router-link replace :to="{ name: 'Home', query: { shareid: uid, merchid: merchid, _t: 3 } }">
            <van-icon name="wap-home-o" />商城首页
          </router-link>
        </van-tabbar-item>
        <van-tabbar-item>
          <router-link replace :to="{ name: 'About', query: { shareid: uid, merchid: merchid, _t: 3 } }">
            <van-icon name="apps-o" />全部分类
          </router-link>
        </van-tabbar-item>
        <van-tabbar-item @click="showCheckService" v-if="merchid == 0 || showkefu == 1">
          <van-icon
            name="service-o"
            :badge="unreadmsgNum > 0 && islogin ? unreadmsgNum : ''"
          />在线客服
        </van-tabbar-item>
        <van-tabbar-item v-if="merchid == 0 || showkefu == 1 ? false : true">
          <router-link replace :to="{ name: 'Distributor', query: { shareid: uid, merchid: merchid } }"> 
            <van-icon name="friends-o" />分销中心
          </router-link>
        </van-tabbar-item>
        <van-tabbar-item>
          <router-link replace :to="{ name: 'Cart', query: { shareid: uid, merchid: merchid, _t: 3 } }"> 
            <van-icon
              name="shopping-cart-o"
              :badge="cartNum > 0 && islogin ? cartNum : ''"
            />购物车
          </router-link>
        </van-tabbar-item>
        <van-tabbar-item>
          <router-link replace :to="{ name: 'Myhome', query: { shareid: uid, merchid: merchid, _t: 3 } }">
            <van-icon name="contact" />会员中心
          </router-link>
        </van-tabbar-item>
      </van-tabbar>
    </div>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />

    <van-dialog
      v-model="showCheckKefu"
      title="请选择客服"
      confirm-button-text="关闭"
    >
      <van-grid :column-num="2" :border="false">
        <van-grid-item icon="service-o" @click="serviceCheck(1)" text="售前客服" />
        <van-grid-item icon="chat-o" @click="serviceCheck(2)" text="售后客服" />
      </van-grid>
    </van-dialog>
  </div>
</template>
<script>
export default {
  beforeRouteEnter(to, from, next) {
    next(vm => { //  这里的vm指的就是vue实例，可以用来当做this使用
      vm.frompage = from.path;
    })
  },
  afterRouteEnter(to, from, next) {
    next(vm => { //  这里的vm指的就是vue实例，可以用来当做this使用
      vm.frompage = from.path;
    })
  },
  data() {
    return {
      active: "0",
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      cartNum: 0, // 购物车产品数量
      shareid: 0, // 分享者mid
      merchid: 0, // 商户id
      uid: 0, // 用户id
      islogin: false,
      userinfo: {},
      unreadmsgNum: 0, // 未读消息数量
      timeoutId: "", // 定时器id
      showkefu: 0,
      merchname: "",
      showCheckKefu: false
    };
  },
  mounted() {
    this.islogin = localStorage.getItem("DK_ISLOGIN")
      ? localStorage.getItem("DK_ISLOGIN")
      : false;
    if (this.islogin) {
      this.getCartNum();
      this.unreadmsgNum = window.unreadmsgnum;
    }
    setTimeout(() => {
      this.showkefu = localStorage.getItem("DK_SHOWKEFU");
    }, 500);

  },

  methods: {
    // 获取分享者id和商户id
    getshaerinfo() {
      this.islogin = localStorage.getItem("DK_ISLOGIN")
      ? localStorage.getItem("DK_ISLOGIN")
      : false;
      if(this.islogin) {
        this.shareid = localStorage.getItem("DK_MEMBERID") ? localStorage.getItem("DK_MEMBERID") : 0;
        // localStorage.setItem("DK_SHAREID", 0);
      }else {
        if (this.$route.query.shareid > 0) {
          localStorage.setItem("DK_SHAREID", this.$route.query.shareid);
          this.shareid = this.$route.query.shareid
        }else{
          this.shareid = localStorage.getItem("DK_SHAREID") ? localStorage.getItem("DK_SHAREID") : 0;
        } 
      }
      
      if (this.$route.query.merchid) {
        
        this.merchid = this.$route.query.merchid
      }else{
        this.merchid = localStorage.getItem("DK_MERCHID") ? localStorage.getItem("DK_MERCHID") : 0;
      }
      this.uid = localStorage.getItem("DK_MEMBERID") ? localStorage.getItem("DK_MEMBERID") : this.shareid;
    },
    getmerchname(merchid, pagename) {
      let _that = null;
      _that = this;
      this.merchid = merchid;
      console.log('shareid:' + this.$route.query.shareid);
      
      
      _that.unreadmsgNum = window.unreadmsgnum;
      this.getClearStorage();
      this.getshaerinfo();
      this.setmerchMber();
      localStorage.setItem("DK_MERCHID", merchid);
      pagename = pagename + "";
      let len = 0;
      len = pagename.length;
      _that.$axios
        .post(
          _that.$store.state.domain + "web/user/get_merchNme",
          _that.$qs.stringify({
            id: merchid
          })
        )
        .then(res => {
          console.log("merchname", res, res.data.merchname);
          _that.$store.state.showkefu = res.data.onlineKefu;
          _that.showkefu = res.data.onlineKefu;
          _that.merchname = res.data.merchname;
          _that.$store.state.merchname = res.data.merchname;
          localStorage.setItem("DK_SHOWKEFU",res.data.onlineKefu);
          localStorage.setItem("DK_SHOPNAME",res.data.merchname);
          let str = "";
          str = res.data.merchname ? res.data.merchname : "";
          if (pagename) {
            str += "-" + pagename;
          }
          document.title = str;
          console.log('closePage2',res.data);
          if(res.data.status == 2) {
            console.log('closePage');
            _that.$router.replace({
              name: "closePage",
              query: {
                shareid : this.$route.query.shareid,
                merchid: this.merchid
              }
            })
          }
        })
    },
    getCartNum() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common[
        "Authorization"
      ] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(
          _that.$store.state.domain + "web/cart/get_goodsnum",
          _that.$qs.stringify({
            merchid: _that.merchid
          })
        )
        .then(response => {
          if (response && response.data && response.data.data) {
            _that.cartNum = response.data.data;
          } else {
            _that.cartNum = 0;
          }
        })
        .catch(error => {
          _that.cartNum = 0;
          console.log(error);
        });
    },
    showCheckService() {
      this.showCheckKefu = true;
    },
    serviceCheck(num) {
      this.showCheckKefu = false;
      this.showqiyu(num);
    },
    showqiyu(fromType) {
      let _that = null;
      _that = this;
      _that.unreadmsgNum = 0;
      
      if (this.merchid == 0 || this.showkefu == 1) {
        
        this.islogin = localStorage.getItem("DK_ISLOGIN");
        
        const jwtStr = localStorage.getItem("DK_UID")
        ? localStorage.getItem("DK_UID")
        : ""; // 公钥
        _that.$axios.defaults.headers.common['Authorization'] = jwtStr;
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(_that.$store.state.domain + "web/user/getinfor")
          .then(function(response) {
            if (response.data.id > 0) {
              _that.userinfo = response.data;
              
              
              let level = 1;
              let levelname = "普通会员";
              if (_that.userinfo.level == '6') {
                level = 2;
              }else if (_that.userinfo.level == '1') {
                level = 3;
              }else if (_that.userinfo.level == '14') {
                level = 4;
              }else if (_that.userinfo.level == '5') {
                level = 5;
              }
              if (_that.userinfo.levelname) {
                levelname = _that.userinfo.levelname;
              }
              if(_that.userinfo.id > 0) {
                let str = "";
                str = JSON.stringify([
                  {"index": 0, "key":"avatar","label":"头像","value":_that.userinfo.avatar},
                  {"index":1, "key":"agent_code", "label":"邀请码", "value":_that.userinfo.agent_code},
                  {"index":2, "key":"level", "label":"等级", "value":levelname},
                  {"index":3, "key":"shopname", "label":"商户名称", "value":_that.merchname},
                  {"index":4, "key":"mobile", "label":"手机号", "value":_that.userinfo.mobile},
                  {"index":5, "key":"shopid", "label":"商户id", "value":_that.merchid},
                  {"index":6, "key":"shareid", "label":"分享者id", "value":_that.shareid}
                ]);
                window.location.href = "https://kefu.midbest.cn/wap/?uid=" + _that.userinfo.id + "&sessionform=" + str + "&fromType=" + fromType;
                return false;
              }
            }else{
              _that.$toast("请先登录")
            }
          })
          .catch(function(error) {
            console.log(error);
          });
        
      }
    },
    getClearStorage() {
      let _that = null;
      _that = this;
      if (localStorage.getItem("DK_ISLOGIN")) {
        _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID")
        // _that.$axios.defaults.headers.common['Token'] = localStorage.getItem("DK_TOKEN")
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/user/get_clear_storage",
            _that.$qs.stringify({
              openid: localStorage.getItem("DK_OPENID")
            }),
            {
              headers: {
                "Token": localStorage.getItem("DK_TOKEN")
              }
            }
          )
          .then(res => {
            console.log(res);
            if (res.data.code == 100000) {
              localStorage.clear();
              _that.replace({
                name: "Myhome",
                query: {
                  shareid: _that.shareid,
                  merchid: _that.merchid
                }
              });
            }
          })
          .catch(err => {
            console.log(err);
          })
      }
    },
    // 子商户绑定会员
    setmerchMber() {
      let _that = null;
      _that = this;
      if (localStorage.getItem("DK_ISLOGIN") && this.merchid > 0) {
        _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID")
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/user/merch_adduser",
            _that.$qs.stringify({
              merchid: _that.merchid
            })
          )
          .then(res => {
            console.log(res);
          })
          .catch(error => {
            console.log(error);
          })
      }
    },
  }
};
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333;
  min-height: 100vh;
  overflow: hidden;
}
.van-cell__title,
.van-cell__value {
  -webkit-box-flex: none;
  -webkit-flex: none;
  flex: none;
}
.van-nav-bar {
  height: 50px;
  line-height: 50px;
  .van-nav-bar__content {
    width: 100%;
  }
}
#nav {
  a {
    display: block;
    font-weight: bold;
    color: #333;
    font-size: 12px;
    font-weight: normal;

    &.router-link-exact-active {
      color: #ee0a24;
    }
  }
  .van-tabbar-item__icon {
    display: none;
  }
  .van-tabbar-item__text {
    width: 100%;
    padding: 5px 0;
  }
  .van-tabbar-item__text .van-icon {
    display: block;
    margin-bottom: 5px;
    font-size: 20px;
  }
  .van-info {
    right: 20%;
  }
}
</style>
