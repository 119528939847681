<template>
  <div class="home">
    <!-- 头部 -->
    <van-nav-bar
      @click-left="onClickLeft"
      @click-right="onClickRight"
      class="title"
      :border="false"
    >
      <template #title>
        <van-search
          shape="round"
          background="#C8241A"
          placeholder="请输入关键词"
          style="padding: 0;"
          :readonly="true"
          @click="goSearch"
        />
      </template>
      <template #left>
        <van-icon name="wap-nav" color="#ffffff" size="24" />
      </template>
      <template #right>
        <van-icon name="chat-o" :badge="userinfo.unreadynum ? userinfo.unreadynum : ''" color="#ffffff" size="24" />
      </template>
    </van-nav-bar>
    <!-- 轮播 -->
    <div class="banner">
      <div
        class="banner_back"
        :style="'min-height:' + ((screenWidth / 680) * 260) / 2 + 'px;'"
      ></div>
      <van-swipe
        class="my-swipe"
        :autoplay="4000"
        duration="1000"
        indicator-color="white"
      >
        <van-swipe-item
          v-for="(item, index) in banner"
          :key="index"
          :style="
            'width:' +
              (screenWidth - 20) +
              'px;height:' +
              (screenWidth / 680) * 260 +
              'px;'
          "
        >
          <van-image :src="item.thumb ? item.thumb : ''" lazy-load @click="bannerLink(index)">
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 客服列表 -->
    <div class="kefu_list" v-if="kefucount > 0">
      <van-grid :column-num="kefucount">
        <van-grid-item icon-size="40px" v-for="(item,index) in kefulist" :key="index" @click="tokefu(item.id, item.is_online)">
          <template #icon>
            <div class="kefu_icon" slot="icon">
              <van-image
                :src="item.header_img"
                lazy-load
                fit="cover"
              >
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
              
              <div class="kefu_job">{{item.type}}</div>
              <div class="kefu_mengban" v-if="item.is_online == 2"></div>
            </div>
          </template>
          <template #text>
          <div class="kefu_name">
            <div :class="item.is_online == 1 ? 'kefu_status online' : 'kefu_status'"></div>
            <div>{{item.name}}</div>
          </div>
          </template>
        </van-grid-item>
      </van-grid>
    </div>
    <!-- 店铺申请 -->
    <div class="apply_shop" v-if="showapply && false">
      <div class="title_text">赚钱秘籍</div>
      <van-cell-group :border="false">
        <van-cell :border="false" center @click="mshegnji">
          <template #title>
            我要卖货
          </template>
          <template #label>
            疾速开店 轻松赚钱
          </template>
          <template #right-icon>
            <van-icon
              size="45"
              name="https://img.midbest.cn/uploads/image/order/kongjia/2020/10/13/bb34f108a3fde8297c27f30e223a89e3.png"
            />
          </template>
        </van-cell>
        <van-cell :border="false" center @click="applyGonghuo">
          <template #title>
            我要供货
          </template>
          <template #label>
            平台扶持 免费入驻
          </template>
          <template #right-icon>
            <van-icon
              name="https://img.midbest.cn/uploads/image/order/kongjia/2020/10/13/6e76a2c091aa1fa64021ce7df42c7a13.png"
              size="45"
              :badge="userinfo.merch_reg && (userinfo.merch_reg.gonghuo_status == -1 || userinfo.merch_reg.gonghuo_qianyue || userinfo.merch_reg.gonghuo_bohui) ? '!' : ''"
            />
          </template>
        </van-cell>
        
      </van-cell-group>
    </div>
    <!-- 投诉建议 -->
    <div class="feedback">
      <van-cell @click="complaint" center>
        <template #title>
          <div>
            投诉建议
          </div>
        </template>
        <template #label>
          <div class="btn_class">
            <span>立即反馈</span>
            <van-icon name="arrow" />
          </div>
        </template>
        <template #right-icon>
          <van-icon :badge="userinfo && userinfo.feedback > 0 ? userinfo.feedback : ''" size="36" name="https://img.midbest.cn/uploads/image/mch0/2020/10/23/3fc49c75f31b258c4b6266f78c26c63c.png" />
        </template>
      </van-cell>
      
      <van-cell @click="cooperation" center>
        <template #title>
          <div>
            供货合作
          </div>
        </template>
        <template #label>
          <div class="btn_class">
            <span>立即合作</span>
            <van-icon name="arrow" />
          </div>
        </template>
        <template #right-icon>
          <van-icon size="40" name="https://img.midbest.cn/uploads/image/mch0/2020/10/23/87193d6503322b9c12eb87c330d7b363.png" />
        </template>
      </van-cell>
      <van-cell :border="false" @click="eduList" center>
        <template #title>
          <div>
            使用教学
          </div>
        </template>
        <template #label>
          <div class="btn_class">
            <span>开始学习</span>
            <van-icon name="arrow" />
          </div>
        </template>
        <template #right-icon>
          <van-icon :badge="userinfo && userinfo.feedback > 0 ? userinfo.feedback : ''" size="36" name="https://img.midbest.cn/uploads/image/order/kongjia/2020/11/05/b10dac0b4a4f3f91c816ffc25135bc19.png" />
        </template>
      </van-cell>
    </div>
    <!-- 每日上新 -->
    <div class="day_new" v-if="daynewlist && daynewlist.length > 0" @click="godaynew(2)">
      <div class="day_new_title">每日上新 <van-icon name="arrow" /></div>
      <div class="day_new_main">
        <van-grid :column-num="4" :border="false" :gutter="10">
          <van-grid-item 
            v-for="(item, index) in daynewlist"
            :key="index"
          >
            <template #default>
              <div class="day_new_list_img">
                <van-image
                  use-loading-slot
                  :width="((screenWidth-30)*0.25-10)"
                  :height="((screenWidth-30)*0.25-10)"
                  :src="item.thumb"
                >
                  <van-loading slot="loading" type="spinner" size="20" vertical />
                </van-image>
                <div class="shuiyin">{{strId}}</div>
              </div>
              <div class="day_new_list_content" :style="'width: ' + ((screenWidth-30)*0.25-20) + 'px;'">
                <div class="day_new_list_title van-multi-ellipsis--l2">{{item.title}}</div>
                <div class="day_new_list_price">&yen;{{item.price}}</div>
              </div>
            </template>
          </van-grid-item>
        </van-grid>
      </div>
    </div>
    <!-- 今日主推 -->
    <div class="day_new" v-if="zhutuilist && zhutuilist.length > 0" @click="godaynew(1)">
      <div class="day_new_title">今日主推 <van-icon name="arrow" /></div>
      <div class="day_new_main">
        <van-grid :column-num="4" :border="false" :gutter="10">
          <van-grid-item 
            v-for="(item, index) in zhutuilist"
            :key="index"
          >
            <template #default>
              <div class="day_new_list_img">
                <van-image
                  use-loading-slot
                  :width="((screenWidth-30)*0.25-10)"
                  :height="((screenWidth-30)*0.25-10)"
                  :src="item.thumb"
                >
                  <van-loading slot="loading" type="spinner" size="20" vertical />
                </van-image>
                <div class="shuiyin">{{strId}}</div>
              </div>
              <div class="day_new_list_content" :style="'width: ' + ((screenWidth-30)*0.25-20) + 'px;'">
                <div class="day_new_list_title van-multi-ellipsis--l2">{{item.title}}</div>
                <div class="day_new_list_price">&yen;{{item.price}}</div>
              </div>
            </template>
          </van-grid-item>
        </van-grid>
      </div>
    </div>
    <!-- midbest专供 -->
    <div class="meiribaopin" v-if="midbestlist && midbestlist.length > 0">
      <div class="meiribaopin_main">
        <van-cell :border="false" @click="midbest" is-link>
          <template #title>Midbest 专场</template>
          <template #default>
            <div class="right_icon">
              <span>查看更多</span>
            </div>
          </template>
        </van-cell>
        <div class="meiribaopin_label">
          <div class="meiribaopin_label_main">
            <div
              class="baopin_list"
              v-for="(item, index) in midbestlist"
              :key="index"
              :style="'width:' + (screenWidth - 20) * 0.3 + 'px;'"
              @click="details(item.id)"
            >
              <div
                class="baopin_list_img"
                :style="'height:' + (screenWidth - 20) * 0.3 + 'px;'"
              >
                <van-image :src="item.thumb" lazy-load>
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
                <div class="shuiyin">{{strId}}</div>
              </div>
              <van-cell
                class="baopin_list_title"
                title-class="van-ellipsis"
                :border="false"
                :title="item.title"
              ></van-cell>
              <van-cell
                class="baopin_list_price"
                title-class="van-ellipsis"
                :border="false"
              >
                <template #title>
                  &yen;{{ item.price }}
                </template>
              </van-cell>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 云店专供 -->
    <div class="meiribaopin" v-if="yundianlist && yundianlist.length > 0">
      <div class="meiribaopin_main">
        <van-cell :border="false" @click="yundian" is-link>
          <template #title>云店专供</template>
          <template #default>
            <div class="right_icon">
              <span>查看更多</span>
            </div>
          </template>
        </van-cell>
        <div class="meiribaopin_label">
          <div class="meiribaopin_label_main">
            <div
              class="baopin_list"
              v-for="(item, index) in yundianlist"
              :key="index"
              :style="'width:' + (screenWidth - 20) * 0.3 + 'px;'"
              @click="details(item.id)"
            >
              <div
                class="baopin_list_img"
                :style="'height:' + (screenWidth - 20) * 0.3 + 'px;'"
              >
                <van-image :src="item.thumb" lazy-load>
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
                <div class="shuiyin">{{strId}}</div>
              </div>
              <van-cell
                class="baopin_list_title"
                title-class="van-ellipsis"
                :border="false"
                :title="item.title"
              ></van-cell>
              <van-cell
                class="baopin_list_price"
                title-class="van-ellipsis"
                :border="false"
              >
                <template #title>
                  &yen;{{ item.price }}
                </template>
              </van-cell>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 分类 -->
    <div class="banner_bottom" v-if="category && category.length > 0">
      <van-swipe
        class="my-swipe"
        duration="500"
        :show-indicators="false"
        :loop="false"
      >
        <van-swipe-item v-for="(item, index) in category" :key="index">
          <van-grid :column-num="5" :border="false">
            <van-grid-item
              v-for="value in item"
              :key="value.id"
              icon="photo-o"
              :text="value.name"
              @click="group(value.id)"
            >
              <template #icon>
                <van-image
                  :src="value.thumb ? value.thumb : categoryimg"
                  width="50px"
                  height="50px"
                  round
                  lazy-load
                >
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
              </template>
            </van-grid-item>
          </van-grid>
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 广告 -->
    <div class="daka_ad" v-if="adsrcStr.thumb">
      <van-image
        :src="adsrcStr.thumb"
        lazy-load
        @click="tourl"
      >
        <template v-slot:loading>
          <van-loading type="spinner" size="20"></van-loading>
        </template>
      </van-image>
    </div>
    <!-- 今日秒杀 -->
    <div class="jinrimiaosha" v-if="miaosha && miaosha.length > 0 ">
      <van-cell-group :border="false">
        <van-cell :border="false" class="jinrimiaosha_title">
          <template #title>
            <span class="miaosha_title_text">今日秒杀</span>
            <van-count-down :time="miaoshatime" />
          </template>
          <template #right-icon>
            <van-button size="mini">
              <span>GO</span>
              <van-icon name="arrow"></van-icon>
            </van-button>
          </template>
        </van-cell>
        <van-cell :border="false" class="jinrimiaosha_content">
          <template #title>
            <van-swipe
              class="my-swipe"
              :autoplay="5000"
              :show-indicators="false"
              indicator-color="white"
              vertical
              duration="1000"
              :style="'height:' + ((screenWidth - 70) / 3 + 44) + 'px;'"
            >
              <van-swipe-item
                v-for="(item, index) in miaosha"
                :key="index"
                :style="'height:' + ((screenWidth - 70) / 3 + 40) + 'px;'"
              >
                <div class="miaosha_list_item">
                  <div
                    class="miaosha_list"
                    v-for="(i, n) in item"
                    :key="n"
                    :data-index="n"
                    :data-id="i.id"
                    :style="'max-width:' + (screenWidth - 70) / 3 + 'px;'"
                  >
                    <div class="miaosha_list_img" @click="details(i.id)">
                      <van-image
                        lazy-load
                        :src="i.thumb"
                        :style="
                          'width:' +
                            (screenWidth - 70) / 3 +
                            'px;height:' +
                            (screenWidth - 70) / 3 +
                            'px;'
                        "
                      >
                        <template v-slot:loading>
                          <van-loading type="spinner" size="20" />
                        </template>
                      </van-image>
                    </div>
                    <div class="miaosha_list_text">
                      <van-cell>
                        <template #title>
                          &yen; {{ i.price }}
                        </template>
                        <template #right-icon>
                          <van-icon name="arrow"></van-icon>
                        </template>
                      </van-cell>
                    </div>
                  </div>
                </div>
              </van-swipe-item>
            </van-swipe>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <!-- 每日爆品 -->
    <div class="meiribaopin" v-if="baopin && baopin.length > 0 && iswx">
      <div class="meiribaopin_main">
        <van-cell :border="false" is-link>
          <template #title>
            <div id="jishiTitle" style="width:100%; dispaly: flex;"></div>
          </template>
          <template #default>
            <div class="right_icon" id="jishiMore"></div>
          </template>
        </van-cell>
        <div class="meiribaopin_label">
          <div class="meiribaopin_label_main" id="baopinList"></div>
        </div>
      </div>
    </div>
    <!-- 新品特价、明日预告 -->
    <div class="mingrixinpin" v-if="(xinpin && xinpin.length > 0) || (yugao && yugao.length > 0)">
      <van-cell :border="false">
        <!-- 新品特价 -->
        <template #title v-if="xinpin && xinpin.length > 0">
          <div
            class="xinpintejia van-hairline--right"
            :style="'width:' + (screenWidth - 20) / 2 + 'px;'"
          >
            <van-cell
              :border="false"
              value-class="van-ellipsis"
              value="底价疯抢中"
              is-link
              @click="xinpintj"
            >
              <template #title>
                <div class="xinpintejia_text">
                  <span class="back_color"></span>
                  <div class="xinpintejia_text_word">新品特价</div>
                </div>
              </template>
            </van-cell>
            <van-cell :border="false" class="xinpintejia_goods_main">
              <template #title>
                <div v-if="xinpin[0]" class="xinpintejia_goods" @click="details(xinpin[0].id)">
                  <div class="xinpintejia_goods_img">
                    <van-image
                      lazy-load
                      :src="xinpin[0].thumb"
                      :style="
                        'width:' +
                          ((screenWidth - 20) / 2 - 30) / 2 +
                          'px;height:' +
                          ((screenWidth - 20) / 2 - 30) / 2 +
                          'px;'
                      "
                    >
                      <template v-slot:loading>
                        <van-loading type="spinner" size="20" />
                      </template>
                    </van-image>
                    <div class="shuiyin">{{strId}}</div>
                  </div>
                  <div class="xinpintejia_goods_text">
                    &yen;{{ xinpin[0].price }}
                  </div>
                </div>
              </template>
              <template #default>
                <div v-if="xinpin[1]" class="xinpintejia_goods" @click="details(xinpin[1].id)">
                  <div class="xinpintejia_goods_img">
                    <van-image
                      lazy-load
                      :src="xinpin[1].thumb"
                      :style="
                        'width:' +
                          ((screenWidth - 20) / 2 - 30) / 2 +
                          'px;height:' +
                          ((screenWidth - 20) / 2 - 30) / 2 +
                          'px;'
                      "
                    >
                      <template v-slot:loading>
                        <van-loading type="spinner" size="20" />
                      </template>
                    </van-image>
                    <div class="shuiyin">{{strId}}</div>
                  </div>
                  <div class="xinpintejia_goods_text">
                    &yen;{{ xinpin[1].price }}
                  </div>
                </div>
              </template>
            </van-cell>
          </div>
        </template>
        <!-- 明日预告 -->
        <template #default v-if="yugao && yugao.length > 0">
          <div class="xinpintejia">
            <van-cell
              :border="false"
              value-class="van-ellipsis"
              value="好物提前知"
              is-link
              @click="xinpintj"
            >
              <template #title>
                <div class="xinpintejia_text">
                  <span class="back_color"></span>
                  <div class="xinpintejia_text_word">明日预告</div>
                </div>
              </template>
            </van-cell>
            <van-cell :border="false" class="xinpintejia_goods_main">
              <template #title>
                <div v-if="yugao[0]" class="xinpintejia_goods" @click="details(yugao[0].id)">
                  <div class="xinpintejia_goods_img">
                    <van-image
                      lazy-load
                      :src="yugao[0].thumb"
                      :style="
                        'width:' +
                          ((screenWidth - 20) / 2 - 30) / 2 +
                          'px;height:' +
                          ((screenWidth - 20) / 2 - 30) / 2 +
                          'px;'
                      "
                    >
                      <template v-slot:loading>
                        <van-loading type="spinner" size="20" />
                      </template>
                    </van-image>
                    <div class="shuiyin">{{strId}}</div>
                  </div>
                  <div class="xinpintejia_goods_text">
                    &yen;{{ yugao[0].price }}
                  </div>
                </div>
              </template>
              <template #default>
                <div v-if="yugao[1]" class="xinpintejia_goods" @click="details(yugao[1].id)">
                  <div class="xinpintejia_goods_img">
                    <van-image
                      lazy-load
                      :src="yugao[1] && yugao[1].thumb ? yugao[1].thumb : ''"
                      :style="
                        'width:' +
                          ((screenWidth - 20) / 2 - 30) / 2 +
                          'px;height:' +
                          ((screenWidth - 20) / 2 - 30) / 2 +
                          'px;'
                      "
                    >
                      <template v-slot:loading>
                        <van-loading type="spinner" size="20" />
                      </template>
                    </van-image>
                    <div class="shuiyin">{{strId}}</div>
                  </div>
                  <div class="xinpintejia_goods_text">
                    &yen;{{ yugao[1] && yugao[1].price ? yugao[1].price : "" }}
                  </div>
                </div>
              </template>
            </van-cell>
          </div>
        </template>
      </van-cell>
    </div>
    <!-- 品牌列表 -->
    <div class="pinpai_list" v-if="brandlist && brandlist.length > 0">
      <van-cell title="合作品牌" value="查看更多" is-link :border="false" center :to="{name: 'BrandList', query: { shareid: shareid, merchid: merchid } }" />
      <van-swipe
        class="my-swipe"
        duration="500"
        :loop="false"
        :width="(screenWidth-20)"
      >
        <van-swipe-item :style="'width:' + (screenWidth-20) + 'px;padding-bottom: 20px;'" v-for="(item, index) in brandlist" :key="index">
          <van-grid :column-num="5" :border="false">
            <van-grid-item
              v-for="value in item"
              :key="value.id"
              :text="value.name"
              @click="brand(value.id)"
              style="overflow: hidden;"
            >
              <template #icon>
                <van-image
                  :src="value.thumb ? value.thumb : categoryimg"
                  width="50px"
                  height="50px"
                  lazy-load
                  style="display: block; border-radius: 4px; overflow: hidden;"
                >
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
              </template>
            </van-grid-item>
          </van-grid>
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 推荐产品列表 -->
    <div class="goods_list" v-if="false">
      <van-list
        v-model="goodsloading"
        :finished="goodsfinished"
        finished-text="没有更多了"
        :immediate-check="false"
        @load="getGoodsList"
      >
        <van-grid :gutter="10" :column-num="2" :border="false">
          <van-grid-item
            v-for="(item, index) in goods"
            :key="item.id"
            :data-index="index"
            :data-id="item.id"
            @click="details(item.id)"
          >
            <template #icon>
              <van-image
                lazy-load
                :src="item.thumb"
                :style="
                  'width:' +
                    (screenWidth - 30) / 2 +
                    'px;height:' +
                    (screenWidth - 30) / 2 +
                    'px;'
                "
                fit="cover"
              >
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
              <div class="shuiyin">{{strId}}</div>
            </template>
            <template #text>
              <div
                class="goods_text van-multi-ellipsis--l2"
                :style="'width:' + ((screenWidth - 30) / 2 - 20) + 'px;'"
              >
                {{ item.title }}
              </div>
              <div
                class="goods_price van-ellipsis"
                :style="'width:' + ((screenWidth - 30) / 2 - 20) + 'px;'"
              >
                <span style="color: #C8241A;">&yen;</span>
                {{ item.price }}
                <span v-if="false">{{ item.sales }}人已购买</span>
              </div>
            </template>
          </van-grid-item>
        </van-grid>
      </van-list>
    </div>
    <!-- 分类产品列表 -->
    <div class="category_goods">
      <van-list
        v-model="goodsloading"
        :finished="goodsfinished"
        finished-text="我是有底线的"
        :immediate-check="false"
        @load="getCategoryGoods"
      >
        <div class="category_goods_main">
          <div
            class="category_goods_list"
            v-for="(item, index) in categoryGoods"
            :key="index"
          >
            <div
              class="category_goods_title"
              v-if="item.list && item.list.length > 0 && item.title"
              @click="group(item.id)"
            >
              {{item.title}}
              <div class="category_goods_more">
                查看更多 <van-icon name="arrow" />
              </div>
            </div>
            <van-grid :border="false" :column-num="3" gutter="10">
              <van-grid-item
                v-for="(val, key) in item.list"
                :key="key"
                class="category_goods_item"
                :data-index="key"
                :data-id="val.id"
                @click="details(val.id)"
              >
                <div class="category_goods_img">
                  <van-image
                    :width="(screenWidth-40)/3"
                    :height="(screenWidth-40)/3"
                    :src="val.thumb"
                    lazy-load
                    fit="cover"
                  >
                    <template v-slot:loading>
                      <van-loading type="spinner" size="20" />
                    </template>
                  </van-image>
                  <div class="shuiyin">{{strId}}</div>
                </div>
                <div class="category_goods_text" :style="'width: ' + ((screenWidth-40)/3-10) + 'px;'">
                  <div class="van-multi-ellipsis--l2" style="height: 32px;line-height: 16px;">{{val.title}}</div>
                  <div class="category_goods_text_row1">&yen;{{val.price}}</div>
                </div>
              </van-grid-item>
            </van-grid>
          </div>
        </div>
      </van-list>
      <van-cell class="postDown" center url="http://www.beian.gov.cn/">
        <template #icon>
          <van-image :src="gongan" width="16px" height="16px" style="margin-right: 10px;"></van-image>
        </template>
        <template #title>
          冀ICP备15029063号-1
        </template>
      </van-cell>
    </div>


    <!-- 广告弹框 -->
    <van-popup
      v-model="msgshow"
      round
      :close-on-click-overlay="false"
      closeable
      :safe-area-inset-bottom="true"
      close-icon="close"
      @click="advTan"
      @click-close-icon.stop="closeAdvTan"
    >
      <div class="van-popup__content" :style="'width: ' + (screenWidth * 0.8) + 'px;'">
        <van-image
          width="100%"
          lazy-load
          :src="msgcontent.content"
        >
          <template v-slot:loading>
            <van-loading type="spinner" size="20" />
          </template>
        </van-image>
        <div v-if="msgcontent.isShouji == 1">
          <van-form @submit="onContactSubmit" ref="ContactFrom">
            <van-field
              v-model="msgcontent.id"
              v-show="false"
              name="advid"
            />
            <van-field
              v-model="contact"
              type="textarea"
              placeholder="请输入您的联系方式"
              name="contact"
            />
            <div style="margin: 16px;">
              <van-button size="small" block type="danger" native-type="submit">提交</van-button>
            </div>
          </van-form>
        </div>
      </div>
    </van-popup>

    <!-- 输入邀请码弹框 -->
    <van-popup
      v-model="showAddAgentCode"
      round
      :close-on-click-overlay="false"
      style="width: 80%;"
    >
      <div class="agent_code">
        <van-field
          v-model="agentCode"
          placeholder="请输入邀请码"
          input-align="center"
          :border="false"
        />
        <van-button
          block
          round
          color="linear-gradient(to right, rgb(255, 96, 52), rgb(238, 10, 36))"
          @click="onAgentCodeConfirm"
          :loading="addAgentBtnLoding"
        >
          保存
        </van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Dialog } from 'vant';
export default {
  name: "Home",
  data() {
    return {
      currSize: 1, // 步数
      currOffset: 0, // 偏移
      goodsloading: false, // 是否加载下一批数据
      goodsfinished: false, // 是否加载完毕
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      adsrcStr: {}, // 本地广告图片
      miaoshatime: 30 * 60 * 60 * 1000, // 倒计时时间戳
      categoryimg: require("../assets/01.png"), // 本地分类图片
      gongan: require("../assets/gongan.png"), // 本地分类图片
      category: [],
      banner: [],
      miaosha: [],
      baopin: [],
      yundianlist: [],
      xinpin: [],
      yugao: [],
      goods: [],
      categoryGoods: [], // 分类产品列表
      goTop: false, // 返回顶部默认隐藏
      shareid: 0,
      merchid: 0,
      strId: "", // 用户id水印
      wxConfig: {
        debug: false, // 开启调试模式 true false
        appId: "wxc465682b705715e5",
        jsApiList: [
          "updateAppMessageShareData",
          "updateTimelineShareData",
          "onMenuShareTimeline",
          "onMenuShareAppMessage"
        ], // 必填，需要使用的JS接口列表
        timestamp: 1585098244, // 必填，生成签名的时间戳
        nonceStr: "dfbfwkm1egc", // 必填，生成签名的随机串
        signature: "d8caa8a88d588a8dc5379dbfbde5d875886baed5", // 必填，签名
        openTagList: ['wx-open-launch-weapp','wx-open-launch-app']
      },
      iswx: false,
      isLogin: localStorage.getItem("DK_ISLOGIN") ? true : false,
      userinfo: {},
      showapply: false,
      msgshow: false, // 是否显示首页弹框
      msgcontent: "", // 首页弹框广告内容
      zhutuilist: [], // 每日上新数据
      daynewlist: [], // 每日上新数据
      showAddAgentCode: false,
      agentCode: '',
      shareagentId: 0,
      addAgentBtnLoding: false,
      midbestlist: [], // midbest 专场
      kefucount: 0, // 客服数量
      kefulist: [], // 客服列表
      brandlist: [], // 品牌列表
      contact: '',
    };
  },
  mounted() {
    this.strId = localStorage.getItem("DK_STRID")
    this.getBanner(); // 获取banner图片
    this.getCategory(); // 获取一级分类
    this.getdaynew();
    // this.getzhutui();
    console.log('mounted___________mounted');
  },
  activated() {
    console.log('activated___________activated');
    this.shareagentId = this.$route.query.shareid ? this.$route.query.shareid : 0;
    
    console.log(localStorage.getItem("DK_ISLOGIN"), "localStorage.getItem(\"DK_ISLOGIN\")");
    if (localStorage.getItem("DK_ISLOGIN") == 'true') {
      this.getShowAddAgentcode();
    }
    if (localStorage.getItem("DK_ISLOGIN")) {
      if (!this.$route.query.shareid || this.$route.query.shareid <= 0 || !this.$route.query._t) {
        this.$router.replace({
          name: "Home",
          query: {
            shareid: localStorage.getItem("DK_MEMBERID"),
            merchid: this.$route.query.merchid ? this.$route.query.merchid : 0,
            _t: 3
          }
        })
      }
    }
    if (this.$route.query.shareid && this.$route.query.shareid > 0) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    if (this.$route.query.merchid) {
      this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    }else if (localStorage.getItem("DK_MERCHID")) {
      this.merchid = localStorage.getItem("DK_MERCHID");
    }

    this.$parent.getmerchname(this.merchid, "");
    this.isLogin = localStorage.getItem("DK_ISLOGIN") ? true : false;
    if (window.isWeixin()) {
      this.iswx = true; 
      setTimeout(() => {
        this.peizhiWxConfig(); // 配置微信分享
      }, 1000);
      
    }

    this.setmerchMber();
    if (this.merchid > 0) {
      this.showapply = false;
      // this.getGoodsList();
      this.getCategoryGoods();
      
    }else{
      this.showapply = true;
    }

    
    
    const OPENID = localStorage.getItem("DK_OPENID") ? true : false;
    if (!OPENID && this.iswx) {
      // 用户授权后获取code值
      this.code = this.$route.query.code ? this.$route.query.code : null;
      if (this.code) {
        this.getwxlog(this.code);
        return false;
      }else {
        this.getwxinfo(); // 自动获取并保存新用户信息
        return false;
      }
    }else{
      if (this.iswx) {
        this.code = this.$route.query.code ? this.$route.query.code : null;
        if (this.code) {
          this.$router.replace({
            name: "Home",
            query: {
              shareid: this.shareid,
              merchid: this.merchid,
              _t: 3
            }
          })
        }
      }
    }
    this.getinfor(); // 获取用户信息
    this.init();
  },
  methods: {
    init() {
      this.getSpike(); // 获取秒杀数据
      this.getBaokuan(); // 获取每日爆品数据
      this.getTomorrowNew(); // 获取新品特价和明日预告
      this.getyundian(); // 云店专供
      this.getMidbestList(); // midbest专供
      let openid = false;
      if (!window.isWeixin()) {
        openid = true; // 本地不刷新
      }
      this.getdefaultadv();
      this.getkefuSet(); // 获取客服列表
      this.getBrandList(); // 获取品牌列表
    },

    // 获取品牌列表
    getBrandList() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/goods/get_default_brand",
          _that.$qs.stringify({
            merchid: _that.merchid,
            size: 1000,
            offset: 0
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.brandlist = res.data.data;
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 查询店铺信息
    getsuofen() {
      let _that = null;
      _that = this;
      let merchid = 0;
      merchid = this.$route.query.merchid;
      console.log(merchid, "home_merchid");
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/user/get_shop_info",
          _that.$qs.stringify({
            id: merchid
          })
        )
        .then(res => {
          console.log(res);
          console.log(res.data.data.merchid, merchid, "dddddd");
          if (res.data.code == 100000) {
            if (merchid != res.data.data.id) {
              _that.$router.replace({
                name: "Home",
                query: {
                  shareid: _that.$route.query.shareid,
                  merchid: res.data.data.id,
                  _t: 3
                }
              })
              window.location.reload();
            }
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 获取是否需要填写邀请码
    getShowAddAgentcode() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(_that.$store.state.domain + "web/user/get_noShare_uid")
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            if (res.data.data.memberList.indexOf(_that.shareagentId) >= 0 && res.data.data.lock_shop < 0) {
              _that.showAddAgentCode = true;
            }else{
              _that.getsuofen();
            }
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 填写邀请码后点击保存按钮
    onAgentCodeConfirm() {
      let _that = null;
      _that = this;
      if (!_that.agentCode || _that.agentCode.length <= 0) {
        _that.$toast("请填写邀请码");
        return false;
      }
      _that.addAgentBtnLoding = true;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/user/check_agent_code",
          _that.$qs.stringify({
            agentcode: _that.agentCode
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.bindAgentCode();
          }else {
            _that.$toast("请填写正确的邀请码");
            _that.addAgentBtnLoding = false;
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 绑定邀请码
    bindAgentCode() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/user/bind_agent_code",
          _that.$qs.stringify({
            agentcode: _that.agentCode
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.showAddAgentCode = false;
            _that.getsuofen();
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 获取会员信息
    getinfor() {
      let _that = null;
      _that = this;
      const jwtStr = localStorage.getItem("DK_UID")
        ? localStorage.getItem("DK_UID")
        : ""; // 公钥
      _that.$axios.defaults.headers.common['Authorization'] = jwtStr;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(_that.$store.state.domain + "web/user/getinfor")
        .then(function(response) {
          _that.isLogin = localStorage.getItem("DK_ISLOGIN")
            ? localStorage.getItem("DK_ISLOGIN")
            : false;
          _that.strId = localStorage.getItem("DK_STRID")
          if (response.data.strId) {
            localStorage.setItem("DK_STRID",response.data.strId)
          }
          if (response.data.id) {
            localStorage.setItem("DK_MEMBERID", response.data.id)
          }
          if (_that.isLogin && response.data) {
            _that.userinfo = response.data;
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 子商户绑定会员
    setmerchMber() {
      let _that = null;
      _that = this;
      if (localStorage.getItem("DK_ISLOGIN") && this.merchid > 0) {
        _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID")
        _that.$axios
          .post(
            _that.$store.state.domain + "web/user/merch_adduser",
            _that.$qs.stringify({
              merchid: _that.merchid
            })
          )
          .then(res => {
            console.log(res);
          })
          .catch(error => {
            console.log(error);
          })
      }
    },

    // 获取今日主推新数据
    getzhutui() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + 'web/goods/get_zhutui_list',
          _that.$qs.stringify({
            offset: 0,
            size: 8,
            default: 1,
            merchid: _that.merchid
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.zhutuilist = res.data.data.goods;
          }
        })
        .catch(err => {
          console.log(err);
        })
    },


    // 获取每日上新数据
    getdaynew() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + 'web/goods/get_new_list',
          _that.$qs.stringify({
            offset: 0,
            size: 8,
            default: 1,
            merchid: _that.merchid
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.daynewlist = res.data.data.goods;
          }
        })
        .catch(err => {
          console.log(err);
        })
    },

    // 进入每日上新列表页
    godaynew(index) {
      this.$router.push({
        name: 'newList',
        query: {
          shareid: this.shareid,
          merchid: this.merchid,
          newlist: index
        }
      })
    },

    /* 设置分享信息 */
    onShare() {
      let _that = null;
      _that = this;
      const mid = localStorage.getItem("DK_MEMBERID"); // 分享者id
      let title = "米叮优品"; // 分享标题
      let desc = "米叮优品"; // 分享简介
      let img = ""; // 分享图片地址
      _that.$axios
        .post(
          _that.$store.state.domain + "web/user/get_merchNme",
          _that.$qs.stringify({
            id: _that.merchid
          })
        )
        .then(res => {
          if (res.data.merchname) {
            title = res.data.merchname;
            desc = res.data.merchname;
          }
          if (res.data.logo) {
            img = res.data.logo;
          }

          window.wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
            window.wx.updateAppMessageShareData({ 
              title: title, // 分享标题
              desc: desc, // 分享描述
              link: _that.$store.state.domain3 + "index?shareid=" + mid + "&merchid=" + _that.merchid, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: img, // 分享图标
              type: "link",
              success: function() {
                console.log("分享设置成功");
              }
            })
          });
        })
    },
    // 分享初始化
    peizhiWxConfig() {
      let _that = null;
      _that = this;
      console.log(_that.iswx);
      /* 获取wx初始化配置 */
      _that.$axios
        .post(
          _that.$store.state.domain + "web/wetch/wx_sign",
          _that.$qs.stringify({
            url: encodeURIComponent(window.location.href)
          })
        )
        .then(function(response) {
          _that.wxConfig.timestamp = response.data.timestamp;
          _that.wxConfig.nonceStr = response.data.nonceStr;
          _that.wxConfig.signature = response.data.signature;
          window.wx.config(_that.wxConfig); // wx初始化配置
          setTimeout(() => {
            _that.onShare();
          }, 100)
        })
        .catch(function(error) {
          console.log(error);
          window.getWx = _that.getWxConfig; // 微信签名过期后调用 外部JS
        });
    },

    /* 获取配置失效 */
    getWxConfig(err) {
      // 如果过期，从html JS 调用重新签名
      if (err.indexOf("invalid signature") != -1) {
        let $this = null;
        $this = this;
        this.$axios
          .post(
            $this.$store.state.domain + "web/wetch/wx_sign",
            $this.$qs.stringify({
              url: encodeURIComponent(window.location.href)
            })
          )
          .then(function(response) {
            console.log(response);
            this.peizhiWxConfig(); // 配置微信分享
            // $this.wxConfig.timestamp = response.data.timestamp;
            // $this.wxConfig.nonceStr = response.data.nonceStr;
            // $this.wxConfig.signature = response.data.signature;
            // window.wx.config($this.wxConfig);
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    },
    // 获取banner图片
    getBanner() {
      let _that = null;
      _that = this;
      _that.$axios
        .post(
          _that.$store.state.domain + "web/adv/get_list",
          _that.$qs.stringify({
            position: [1,2],
            merchid: _that.$route.query.merchid
          })
        )
        .then(response => {
          console.log(response);
          if (response.data.code == 100000) {
            _that.banner = [];
            response.data.data.forEach(res => {
              if (res.position == "1") {
                _that.banner.push(res);
              }else if(res.position == "2") {
                _that.adsrcStr = res;
              }
            })
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    // 获取一级分类
    getCategory() {
      let _that = null;
      _that = this;
      this.$axios
        .get(this.$store.state.domain + "web/category/get_onelist?merchid=" + _that.merchid)
        .then(function(response) {
          if (response && response.data) {
            _that.category = response.data;
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 获取秒杀数据
    getSpike() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID") ? localStorage.getItem("DK_UID") : "";
      this.$axios
        .get(this.$store.state.domain + "web/goods/get_miaosha_goods?merchid=" + _that.merchid)
        .then(function(response) {
          if (response && response.data) {
            _that.miaosha = response.data;
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 获取每日爆品数据
    getBaokuan() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID") ? localStorage.getItem("DK_UID") : "";
      this.$axios
        // .get(this.$store.state.domain + "web/goods/get_recommand_goods?merchid=" + _that.merchid)
        .get(this.$store.state.domain + "web/goods/get_jishi_goods?merchid=" + _that.merchid)
        .then(function(response) {
          if (response && response.data) {
            _that.baopin = response.data;
            if (_that.iswx) {
              const title = `<wx-open-launch-weapp
                style="dispaly: flex;flex: 1; height: 24px; line-height: 24px;"
                id="launch-btn_title"
                username="gh_47ca22f7d117"
                path="ershou/jishiList/jishiList"
              >
                <template>
                  <div style="flex: 1; font-size: 16px; font-weight: bold; letter-spacing: 2px;">云店集市</div>
                </template>
              </wx-open-launch-weapp>`;
              setTimeout(() => {
                const jishiTitle = document.getElementById("jishiTitle");
                jishiTitle.innerHTML = title;
              }, 500);

              const more = `<wx-open-launch-weapp
                  style="dispaly: block;line-height: 24px; width: 100%;"
                  id="launch-btn_more"
                  username="gh_47ca22f7d117"
                  path="ershou/jishiList/jishiList"
                >
                  <template>
                    <div style="color: #999; line-height: 24px; font-size: 12px;text-align: right;">查看更多</div>
                  </template>
                </wx-open-launch-weapp>`;
              setTimeout(() => {
                const jishiMore = document.getElementById("jishiMore");
                jishiMore.innerHTML = more;
              }, 500);

              let list = "";
              let marginRight = "";
              _that.baopin.forEach((item, index) => {
                if(index == _that.baopin.length-1) {
                  marginRight = "margin-right: 0;"
                }
                list += `<wx-open-launch-weapp
                  id="launch-btn` + index + `"
                  username="gh_47ca22f7d117"
                  path="/ershou/goodsdetail/goodsdetail?id=` + item.id + `"
                  style="width:` + ((_that.screenWidth - 20) * 0.3) + `px;height: `+ ((_that.screenWidth - 20) * 0.3 + 44) +`px; overflow: hidden; position: relative; margin-right: 10px; display: inline-block;`+ marginRight +`"
                >
                  <template>
                    <div style="height:` +( (_that.screenWidth - 20) * 0.3) + `px; width:` +( (_that.screenWidth - 20) * 0.3) + `px; position: relative; overflow: hidden; border-radius: 4px;">
                      <image src="`+item.thumb+`" style="width: 100%; height: 100%; display: block;" />
                      <div style="padding: 2px; background-color:#fff; position: absolute; left: 0; bottom: 0; color: #808080; opacity: 0.5; font-size: 14px; height: 16px; line-height: 16px;">` + _that.strId + `</div>
                    </div>
                    <div style="letter-spacing: 0px; color: #666; font-size: 13px; line-height: 24px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">` + item.title + `</div>
                    <div style="letter-spacing: 0px; color: #f6003d; line-height: 20px; height: 20px; text-align: center;">&yen;` + item.price + `</div>
                  </template>
                </wx-open-launch-weapp>`;
              });
              setTimeout(() => {
                const jishi = document.getElementById("baopinList");
                jishi.innerHTML = list;
              }, 500);
            }
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 获取新品特价和明日预告
    getTomorrowNew() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID") ? localStorage.getItem("DK_UID") : "";
      this.$axios
        .get(this.$store.state.domain + "web/goods/get_hot_goods?merchid=" + _that.merchid)
        .then(function(response) {
          if (response && response.data) {
            if (response.data.hot) {
              _that.yugao = response.data.hot;
            }
            if (response.data.new) {
              _that.xinpin = response.data.new;
            }
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 云店专供产品
    getyundian() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/goods/get_yundian_list",
          _that.$qs.stringify({
            merchid: _that.merchid,
            default: 1,
            groupid: 422
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.yundianlist = res.data.data;
          }
        })
        .catch(err => {
          console.log(err);
        })
    },

    /**
     * 获取 midbest专场 产品列表
     */
    getMidbestList() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/goods/get_midbest_list",
          _that.$qs.stringify({
            merchid: _that.merchid,
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.midbestlist = res.data.data;
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 获取产品列表
    getGoodsList() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID") ? localStorage.getItem("DK_UID") : "";
      if (!_that.goodsfinished) {
        _that.$axios
          .post(
            _that.$store.state.domain + "web/goods/get_home_list",
            _that.$qs.stringify({
              size: _that.currSize,
              offset: _that.currOffset,
              merchid: _that.merchid
            })
          )
          .then(function(response) {
            _that.goodsloading = false; // 本次数据加载完成
            if (response && response.data && response.data.length > 0) {
              response.data.forEach((item, index) => {
                if (parseInt(item.sales) > 10000) {
                  response.data[index].sales =
                    (item.sales / 10000).toFixed(1) + "万";
                }
                _that.goods.push(response.data[index]);
              });
              if (response.data.length < _that.currSize) {
                _that.goodsfinished = true; // 加载完毕
              }
              _that.currOffset += response.data.length;
            }else {
              _that.goodsfinished=true;
            }
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    },
    // 获取分类产品列表
    getCategoryGoods() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID") ? localStorage.getItem("DK_UID") : "";
      if (!_that.goodsfinished) {
        _that.$axios
          .post(
            _that.$store.state.domain + "web/goods/category_goods",
            _that.$qs.stringify({
              size: _that.currSize,
              offset: _that.currOffset,
              merchid: _that.merchid
            })
          )
          .then(function(response) {
            _that.goodsloading = false; // 本次数据加载完成
            if (response && response.data && response.data.code == 100000) {
              response.data.data.forEach((item, index) => {
                _that.categoryGoods.push(item);
              });
              console.log(response.data.data[0]);
              if (response.data.data.length < _that.currSize) {
                _that.goodsfinished = true; // 加载完毕
              }
              _that.currOffset += response.data.data.length;
            }else {
              _that.goodsfinished = true;
            }
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    },
    // 点击左侧按钮
    onClickLeft() {
      this.$router.replace({ name: "About", query: { shareid: this.shareid, merchid: this.merchid, _t: 3 } });
    },
    // 点击右侧按钮
    onClickRight() {
      this.$router.push({ name: "MessageList", query: { shareid: this.shareid, merchid: this.merchid } });
    },
    // 点击产品进入产品详情页
    details(id) {
      this.$router.push({ name: "Details", query: { id: id, shareid: this.shareid, merchid: this.merchid, _t: 3 } });
    },
    // 点击搜索框跳转到搜索列表页
    goSearch() {
      this.$router.push({ name: "Search", query: { shareid: this.shareid, merchid: this.merchid } });
    },
    // 点击分类按钮
    group(id) {
      console.log(id);
      if (parseInt(id) > 0) {
        this.$router.push({ name: "List", query: {groupid: id, shareid: this.shareid, merchid: this.merchid } })
      }
    },
    // 点击品牌按钮
    brand(id) {
      this.$router.push({ name: "List", query: {brandid: id, shareid: this.shareid, merchid: this.merchid } })
    },
    // 爆品跳转
    baopintz() {
      this.$router.push({ name: "List", query: { groupid: 0, sort: 2, shareid: this.shareid, merchid: this.merchid } })
    },
    // 新品特价
    xinpintj() {
      this.$router.push({ name: "List", query: { groupid: 0, sort: 0, shareid: this.shareid, merchid: this.merchid } })
    },
    // 云店专供
    yundian() {
      this.$router.push({ name: "YunList", query: { groupid: 422, sort: 0, shareid: this.shareid, merchid: this.merchid } })
    },
    // midbest 专场
    midbest() {
      this.$router.push({ name: "List", query: { brandid: 27, sort: 0, shareid: this.shareid, merchid: this.merchid } })
    },
    // 马上升级
    mshegnji() {
      let _that = null;
      _that = this;
      if (!this.isLogin) {
        this.$notify({
          type: "warning",
          message: "请登录后再申请"
        })
        if (!this.iswx) {
          setTimeout(() => {
            _that.$router.push({ name: "Login", query: { shareid: this.shareid, merchid: this.merchid } });
          }, 1000);
        }else {
          // 微信中
          const OPENID = localStorage.getItem("DK_OPENID");
          const WXINFO = localStorage.getItem("DK_WXINFO");
          if(!OPENID) {
            _that.getwxinfo(); // 在微信中打开获取openid或用户信息
          }else if(WXINFO){
            this.loginshow = true;
            _that.getparentInfo();
            setTimeout(() => {
              _that.btnDisabled = true;
            }, 20);
          }
        }
      }else {
        if (this.userinfo.merch_reg.merchtype == 12) {
          _that.$router.push({ name: "merchTishi", query: {pagetitle: "联盟店铺", shareid: _that.shareid, merchid: _that.merchid} });
          return false;
        }
        if (this.userinfo.merch_reg && this.userinfo.merch_reg.lianmeng_id) {
          if (this.userinfo.merch_reg.lianmeng_status == 0) {
            this.$toast("您已经申请联盟店，等待客服审核中");
            return false;
          }
          if (this.userinfo.merch_reg.lianmeng_status == 1) {
            _that.$router.push({ name: "merchTishi", query: {shareid: _that.shareid, merchid: _that.merchid} });
            return false;
          }
        }else{
          if (this.userinfo.level1_white == 0) {
            this.$toast("等级不符合要求");
            return false;
          }else{
            // 已经登陆直接进入申请页面
            this.$router.push({ name: "FenxiaoApply1", query: { shareid: this.shareid, merchid: this.merchid } });
          }
        }
      }
    },
    // 申请供货商
    applyGonghuo() {
      let _that = null;
      _that = this;
      if (!this.isLogin) {
        this.$notify({
          type: "warning",
          message: "请登录后再申请"
        })
        if (!this.iswx) {
          setTimeout(() => {
            _that.$router.push({ name: "Login", query: { shareid: this.shareid, merchid: this.merchid } });
          }, 1000);
        }else {
          // 微信中
          const OPENID = localStorage.getItem("DK_OPENID");
          const WXINFO = localStorage.getItem("DK_WXINFO");
          if(!OPENID) {
            _that.getwxinfo(); // 在微信中打开获取openid或用户信息
          }else if(WXINFO){
            this.loginshow = true;
            _that.getparentInfo();
            setTimeout(() => {
              _that.btnDisabled = true;
            }, 20);
          }
        }
      }else {
        if (this.userinfo.merch_reg && this.userinfo.merch_reg.gonghuo_qianyue) {
          // window.location.href = this.userinfo.merch_reg.gonghuo_qianyue;
          this.$router.push({ name: "GonghuoQianyue", query: {shareid: this.shareid, merchid: this.merchid} });
          return false;
        }
        if (this.userinfo.merch_reg && this.userinfo.merch_reg.gonghuo_bohui) {
          this.$router.push({ name: "GonghuoApply2", query: { shareid: this.shareid, merchid: this.merchid } });
          return false;
        }
        if (this.userinfo.merch_reg && this.userinfo.merch_reg.gonghuo_status == 1) {
          this.$toast("您已经申请供货");
          return false;
        }
        if (this.userinfo.merch_reg.gonghuo_status == 0) {
          this.$toast("您已经申请供货，等待客服审核中");
          return false;
        }else{
          if (this.userinfo.merch_reg.gonghuo_id) {
            // 已驳回进行修改
            this.$router.push({ name: "GonghuoApply2", query: { shareid: this.shareid, merchid: this.merchid } });
          }else{
            // 已经登陆直接进入申请页面
            this.$router.push({ name: "GonghuoApply1", query: { shareid: this.shareid, merchid: this.merchid } });
          } 
        }
      }
    },
    /* 判断是否在微信中打开，如在微信中打开获取openid或用户信息 */
    getwxinfo() {
      let _that = null;
      _that = this;
      /* 以下下是获取openID 以及判断用户是否在微信中打开页面 */
      let openid = null;
      openid = localStorage.getItem("DK_OPENID");
      if (!window.isWeixin()) {
        openid = true; // 在H5页面中打开
        this.iswx = false; 
      }

      /* 在微信中打开页面 */
      if (!openid && this.iswx) { 
        this.iswx = true; 
        const wxAuthUrl2 = 
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc465682b705715e5&redirect_uri=" + encodeURIComponent(window.location.href) + "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
          // 传到后台得到openid, 其中access_token 2小时过期
          if (_that.code == null || _that.code === "") {
            window.location.href = wxAuthUrl2; // [自刷新当前页]
          }
      } else {
        // localStorage存在openid || 本页面不在公众号中打开
        console.log("localStorage存在openid || 本页面不在公众号中打开");
      }
    },

    // 获取用户微信信息
    getwxlog(code) {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/wetch/wx_oauth2",
          _that.$qs.stringify({
            code: code
          })
        )
        .then(function(response) {
          if (response.data) {
            localStorage.setItem("DK_OPENID", response.data); // 获得用户的openid
            _that.$axios
              .post(
                _that.$store.state.domain + "web/wetch/get_wxsnsinfo",
                _that.$qs.stringify({
                  openid: response.data
                })
              )
              .then(res => {
                if (res.data && res.data.headimgurl) {
                  if (res.data.openid == response.data) {
                    localStorage.setItem("DK_WXINFO", _that.$qs.stringify(res.data));
                    localStorage.setItem("DK_UNIONID", res.data.unionid);
                    setTimeout(() => {
                      /* 保存openID */
                      _that.saveOpenid();
                    }, 200)
                  }
                }
              })
              .catch(error => {
                console.log(error);
              })
          }
        })
        .catch(error => {
          console.log(error);
        })
    },

    /**
     * 获取客服列表
     */
    getkefuSet() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID")
      _that.$axios
        .post("https://kefu.midbest.cn/manage/Home/getUserList")
        .then(res => {
          console.log(res);
          if (res.data.code == 200) {
            _that.kefucount = res.data.data.count;
            _that.kefulist = res.data.data.list;
          }else {
            _that.kefucount = 0;
            _that.kefulist = [];
          }
        })
        .catch(err => {
          console.log(err);
        })
      
    },

    // 进入客服页面
    tokefu(id,isOnline) {
      console.log(id);
      if (parseInt(isOnline) == 1) {
        this.$parent.showqiyu(id);
      } else {
        Dialog.confirm({
          title: '提示',
          message: '当前客服不在线，不能及时回复您的问题,是否确认发送信息给该客服？',
        })
        .then(() => {
          // on confirm
          this.$parent.showqiyu(id);
        })
        .catch(() => {
          // on cancel
        });
      }
    },

    /* 如果用户登录中未获的用户openID 保存用户openID */
    saveOpenid() {
      let _that = null;
      _that = this;
      this.loadshow = true;
      let openid = "";
      let unionid = "";
      openid = localStorage.getItem("DK_OPENID");
      unionid = localStorage.getItem("DK_UNIONID");
      if (openid) {
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/user/getmidByOpenid", // 查询user_open表有没有mid
            _that.$qs.stringify({
              openid: openid,
              unionid: unionid
            })
          )
          .then(res => {
            if (res.data.code == 100000) { // 有mid 登陆成功
              localStorage.setItem("DK_MEMBERID", res.data.data);
              // 查询会员信息
              _that.$axios
                .post(
                  _that.$store.state.domain + "web/user/get_info_byMid",
                  _that.$qs.stringify({
                    id: res.data.data 
                  })
                )
                .then(response => {
                  if (response.data.code == 100000) {
                    localStorage.setItem("DK_UID",response.data.data);
                    localStorage.setItem("DK_ISLOGIN",true);
                    localStorage.setItem("DK_STRID",response.data.user.strId);
                    localStorage.setItem("DK_TOKEN",response.data.user.pwd);
                    _that.shareid = response.data.user.id;
                    _that.loadshow = false;
                    _that.reloapage(); // 重新加载页面去除code参数 防止刷新报错
                  }
                })
                .catch(error => {
                  console.log(error);
                })
            }else { // 没有mid
              _that.loadshow = false;
              // 根据openID查询会员信息
              _that.getMidByMember();
            }
          })
          .catch(error => {
            console.log(error);
          })
      }
    },

    // 判断是否是新用户
    getMidByMember() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/user/get_mid",
          _that.$qs.stringify({
            openid: localStorage.getItem("DK_OPENID"),
            unionid: localStorage.getItem("DK_UNIONID")
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            // 已经有账号，请登录
            _that.$toast("请登录");
            _that.lijidenglu(); // 跳转登陆页面
          }else if(res.data.code == 100001) {
            // 未有账号，创建账号，并且建立上下级关系
            _that.createAccount();
          }else {
            _that.reloapage(); // 重新加载页面去除code参数 防止刷新报错
          }
        })
        .catch(err => {
          console.log(err);
        })
    },

    // 创建账号，并且保存上下级关系
    createAccount() {
      let _that = null;
      _that = this;

      let req = null;
      req = localStorage.getItem("DK_WXINFO") ? _that.$qs.parse(localStorage.getItem("DK_WXINFO")) : {};
      req.agentid = _that.shareid;
      req.merchid = _that.merchid;
      _that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/user/wetch_register",
          _that.$qs.stringify(
            req
          )
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            localStorage.setItem("DK_UID",res.data.data);
            localStorage.setItem("DK_ISLOGIN",true);
            localStorage.setItem("DK_STRID",res.data.user.strId);
            _that.shareid = res.data.user.id;
            _that.loadshow = false;
            _that.reloapage(); // 重新加载页面去除code参数 防止刷新报错
          }
        })
        .catch(err => {
          console.log(err);
        })
    },

    // 登陆
    lijidenglu() {
      this.$router.push({ name: "Login", query: { shareid: this.shareid, merchid: this.merchid } }); 
    },

    /* 重新刷新页面 */
    reloapage() {
      let _that = null;
      _that = this;
      _that.getinfor();
      _that.$router.replace({ name: "Home", query: {shareid: _that.shareid, merchid: _that.merchid, _t: 3 } });// 成功后重定向，需要去除code参数，防止刷新报错(localStorage['DK_OPENID']控制是否刷新code)
    },
    // 投诉建议
    complaint() {
      this.$router.push({name: "ComplaintList", query: { shareid: this.shareid, merchid: this.merchid } });
    },
    // 供货合作
    cooperation() {
      this.$router.push({name: "Cooperation", query: { shareid: this.shareid, merchid: this.merchid } });
    },
    // 开始学习
    eduList() {
      this.$router.push({name: "VideoList", query: {shareid: this.shareid, merchid: this.merchid}});
    },
    // banner图跳转
    bannerLink(index) {
      console.log(this.banner);
      if(this.banner[index].link && this.banner[index].link.length >= 10) {
        this.getAdvUrl(this.banner[index].link, this.banner[index].urltype, index);
      }
    },
    // 首页广告图跳转
    tourl() {
      // console.log(this.adsrcStr);
      // if(this.adsrcStr.link && this.adsrcStr.link.length >= 10) {
      //   this.getAdvUrl(this.adsrcStr.link, this.adsrcStr.urltype);
      // }
      this.$router.push({
        name: "activityList",
        query: {
          shareid: this.shareid,
          merchid: this.merchid
        }
      })
    },
    // 获取首页弹框广告
    getdefaultadv() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/adv/get_default_tan",
          _that.$qs.stringify({
            merchid: _that.merchid
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            if (res.data.data.content && res.data.data.content.length > 0) {
              _that.msgshow = true; // 是否显示首页弹框
              _that.msgcontent = res.data.data; // 首页弹框广告内容
            }
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 关闭弹框广告
    closeAdvTan() {
      console.log(this.msgcontent);
      let _that = null;
      _that = this;
      _that.msgshow = false;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/adv/set_default_tan",
          _that.$qs.stringify({
            id: _that.msgcontent.id
          })
        )
        .then(res => {
          console.log(res);
        })
        .catch(err => {
          console.log(err);
        })
    },

    // 点击弹框广告
    advTan() {
      let _that = null;
      _that = this;
      
      if (this.msgcontent.is_url == 1) {
        _that.msgshow = false;
        _that.closeAdvTan();
        setTimeout(() => {
          _that.getAdvUrl(_that.msgcontent.url, _that.msgcontent.urltype);
        }, 500);
      }
    },

    // 执行跳转
    getAdvUrl(url,type, index=-1) {
      let _that = null;
      _that = this;
      console.log(url, type);
      if (type == 0) { // 站内跳转
        if (url && url.length > 10) {
          let urlArr = [];
          urlArr = url.split("?");
          let newurl = urlArr[0] + "?";
          if(urlArr[1]) {
            urlArr = urlArr[1].split("&");
            urlArr.forEach(item => {
              if (item.indexOf('shareid=') >= 0) {
                newurl += "shareid=" + _that.shareid + "&";
              }else if (item.indexOf('merchid=') >= 0) {
                newurl += "merchid=" + _that.merchid + "&";
              }else {
                newurl += item + "&";
              }
            })
          }
          console.log(newurl);
          window.location.href = newurl;
        }
      }else if (type == 1) { // 站外跳转
        if (url && url.length > 10) {
          window.location.href = url;
        }
      }else if (type == 2) {
        console.log("小程序跳转");
        if (url && url.length > 10) {
          if (url == "/pages/goods/details") { // 产品详情页
            let id = 0;
            if (index >= 0) {
              console.log(_that.banner[index], "console.log(this.msgcontent);");
              id = _that.banner[index].goodsid;
            } else {
              id = _that.msgcontent.goodsid;
            }
            _that.$router.push({
              name: "Details",
              query: {
                id: id,
                shareid: _that.shareid,
                merchid: _that.merchid
              }
            })
          }else if (url == "/pages/list/list") { // 列表页
            let keywords = '';
            if (index >= 0) {
              keywords = _that.banner[index].goodskey;
            } else {
              keywords = _that.msgcontent.goodskey;
            }
            _that.$router.push({
              name: "List",
              query: {
                keywords: _that.msgcontent.keywords,
                shareid: _that.shareid,
                merchid: _that.merchid
              }
            })
          }else if (url == "/pages/list/list?brandId=") { // 品牌列表页
            let id = 0;
            if (index >= 0) {
              console.log(_that.banner[index], "console.log(this.msgcontent);");
              id = _that.banner[index].brandid;
            } else {
              id = _that.msgcontent.brandid;
            }
            _that.$router.push({
              name: "List",
              query: {
                brandid: id,
                shareid: _that.shareid,
                merchid: _that.merchid
              }
            })
          }else if (url == "/pages/activity/list/list?keywords=") { // 活动列表页
            let keywords = '';
            if (index >= 0) {
              keywords = _that.banner[index].activityname;
            } else {
              keywords = _that.msgcontent.activityname;
            }
            _that.$router.push({
              name: "activityList",
              query: {
                keywords: keywords,
                shareid: _that.shareid,
                merchid: _that.merchid
              }
            })
          }
        }
      }else if (type == 3) {
        console.log("App跳转");
      }
    },
    
    // 保存用户输入内容
    onContactSubmit() {
      console.log(this.contact, this.msgcontent.id);
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/user/add_member_contact",
          _that.$qs.stringify({
            id: _that.msgcontent.id,
            content: _that.contact
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.closeAdvTan();
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
  }
};
</script>
<style lang="less">
.home {
  width: 100%;
  text-align: left;
  min-height: 100%;
  background-color: #ededed;
  overflow: hidden;
  padding-bottom: 94px;
  // 标题
  .title {
    background: #c8241a;
    display: flex;
    .van-nav-bar__title {
      max-width: 100%;
      flex: 1;
      .van-cell__value {
        flex: 1;
      }
      .van-field__left-icon {
        .van-icon {
          color: #999;
        }
      }
    }
    .van-nav-bar__left,
    .van-nav-bar__right {
      position: static;
      height: 50px;
      line-height: 50px;
      width: 50px;
      padding: 0;
      text-align: center;
      .van-icon {
        margin: 0 auto;
      }
    }

    .van-cell {
      padding: 0;
      height: 32px;
      line-height: 32px;
      background: #fff;
      padding: 0 10px;
      border-radius: 30px;
    }
  }
  // 轮播图样式
  .banner {
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    margin-bottom: 10px;
    .banner_back {
      width: 100%;
      background-color: #c8241a;
      border-radius: 0 0 50% 50%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }
    .my-swipe {
      text-align: center;
    }
    .my-swipe .van-swipe-item {
      text-align: center;
      padding: 0 10px;
      .van-image {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        overflow: hidden;
      }
    }
  }
  // 开店申请
  .apply_shop {
    overflow: hidden;
    margin: 10px;
    padding: 1px 2px 2px 2px;
    background: #C8241A;
    border-radius: 10px;
    overflow: hidden;
    .title_text {
      color: #fff;
      font-size: 14px;
      background: #C8241A;
      margin: 0;
      text-align: center;
      height: 24px;
      line-height: 24px;
      width: 100%;
      letter-spacing: 2px;
    }
    .van-cell-group {
      display: flex;
      padding: 14px 5px;
      border-radius: 10px;
      .van-cell {
        flex: 1;
        padding: 0 10px;
        overflow: visible;
        background-color: #fff;
        .van-cell__title,
        .van-cell__value {
          flex: 1;
          color: #333;
          font-size: 16px;
          font-weight: bold;
          text-align: left;
        }
        .van-cell__label {
          font-weight: normal;
          color: #DF4334;
          font-size: 10px;
        }
        &:last-of-type {
          border-left: 1px solid #ccc;
        }
      }
    }
    
  }
  // 投诉建议 与 供货合作
  .feedback {
    margin: 10px;
    overflow: hidden;
    display: flex;
    padding: 10px 0px;
    background-color: #fff;
    border-radius: 10px;
    .van-cell {
      padding: 0px 8px;
      position: relative;
      &::after {
        right: 0;
        top: 2px;
        bottom: 2px;
        border-right: 1px solid #ebedf0;
        border-bottom: 0;
      }
      &:last-of-type {
        border-right: none;
      }
      .van-cell__title {
        flex: 1;
        line-height: 20px;
        .van-cell__label {
          margin-top: 0;
          .btn_class {
            font-size: 10px;
            height: 20px;
            line-height: 20px;
            display: -webkit-box;
            display: -webkit-flex;
            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
            align-items:center;
            span {
              display: inline-block;
              height: 20px;
              line-height: 20px;
              vertical-align: middle;
            }
            .van-icon {
              height: 20px;
              line-height: 20px;
              vertical-align: middle;
            }
          }
        }
      }
      .van-icon .van-info {
        top: 2px;
        right: 7px;
      }
    }
  }
  // 每日上新
  .day_new {
    overflow: hidden;
    position: relative;
    margin: 10px;
    border-radius: 10px;
    background-color: #fff;
    .day_new_title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      line-height: 30px;
      padding-bottom: 10px;
      color: #fff;
      font-size: 14px;
      width: 100%;
      background-color: #c8241a;
    }
    .day_new_main {
      padding: 10px 0;
      overflow: hidden;
      border-radius: 10px;
      margin-top: -10px;
      background-color: #fff;
      .van-grid-item__content {
        padding: 0;
        .day_new_list_img {
          position: relative;
          .van-image {
            display: block;
          }
        }
      }
      .day_new_list_content {
        padding: 5px;
        font-size: 12px;
        .day_new_list_price {
          margin-top: 5px;
          color: #e42920;
        }
      }
    }
  }
  // 轮播图下面的分类样式
  .banner_bottom {
    padding-bottom: 10px;
    background-color: #fff;
    min-height: 176px;
    .van-grid-item__content {
      padding: 16px 10px 0;
      .van-grid-item__text {
        display: inline-block;
        height: 18px;
        line-height: 18px;
        overflow: hidden;
        font-size: 12px;
      }
      .van-image {
        background-color: #f7f7f7;
      }
    }
  }
  // 客服列表
  .kefu_list {
    overflow: hidden;
    margin: 10px;
    border-radius: 10px;
    background-color: #fff;
    .van-grid-item__icon {
      height: 40px;
    }
    .kefu_icon {
      position: relative;
      height: 40px;
      width: 40px;
      overflow: hidden;
      border-radius:4px;
      .van-image {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .kefu_job {
      position: absolute;
      bottom: 0;
      right: 0;
      height: 12px;
      line-height: 12px;
      padding: 0 2px;
      background: rgba(0, 0,0,0.5);
      color: #fff;
      font-size: 9px;
    }
    .kefu_job {
      position: absolute;
      bottom: 0;
      right: 0;
      height: 12px;
      line-height: 12px;
      padding: 0 2px;
      background: rgba(0, 0,0,0.5);
      color: #fff;
      font-size: 9px;
    }
    .kefu_mengban {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.4);
    }
    .kefu_name {
      display: flex;
      align-items: center;
      font-size: 12px;
      margin-top: 5px;
    }
    .kefu_status {
      width: 4px;
      height: 4px;
      display: block;
      border-radius: 50%;
      background-color: #999;
      margin-right: 5px;
    }
    .online {
      background-color: #07C160;
    }
  }
  // 广告位
  .daka_ad {
    margin: 10px;
    overflow: hidden;
    .van-image {
      display: block;
      border-radius: 10px;
      overflow: hidden;
    }
  }
  // 今日秒杀
  .jinrimiaosha {
    padding: 0 10px 10px;
    .van-cell-group {
      border-radius: 10px;
      overflow: hidden;
    }
    .van-cell {
      padding: 10px;
      overflow: hidden;
    }
    .jinrimiaosha_title {
      background-image: linear-gradient(to right, #fe6657, #e42920);
      border-radius: 10px 10px 0 0;
      color: #fff;
      overflow: hidden;
      .van-cell__title {
        flex: 1;
      }
      .miaosha_title_text {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 2px;
        margin-right: 10px;
      }
      .van-count-down {
        display: inline-block;
        background: #8c1511;
        padding: 2px 16px;
        border-radius: 30px;
        color: #fff;
        letter-spacing: 1px;
      }
      .van-button {
        height: 24px;
        line-height: 23px;
        border-radius: 30px;
        text-align: center;
        vertical-align: middle;
        background-color: #fee3ce;
        border-color: #fc9c86;
      }
      .van-button--mini {
        padding: 0 10px;
      }
      .van-button__text span {
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        font-size: 16px;
        letter-spacing: 1px;
        color: #c80000;
        font-weight: bold;
      }
      .van-icon {
        vertical-align: middle;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        line-height: 16px;
        text-align: center;
        color: #fff;
        font-size: 10px;
        background-color: #f98570;
      }
    }
    .jinrimiaosha_content {
      background-color: #f03637;
      border-radius: 0 0 10px 10px;
      overflow: hidden;
      padding: 10px 5px;
      .van-cell__title {
        flex: 1;
      }
      .my-swipe .van-swipe-item {
        padding: 2px 0;
        position: relative;
        overflow: hidden;
        .miaosha_list_item {
          display: flex;
          overflow: hidden;
          .miaosha_list {
            border-radius: 5px;
            background-color: #ffdecc;
            padding: 5px;
            margin-right: 5px;
            flex: 1;
            min-height: 60px;
            &:last-of-type {
              margin-right: 0;
            }
            .miaosha_list_img {
              border-radius: 5px 5px 0 0;
              .van-image {
                border-radius: 4px 4px 0 0;
                overflow: hidden;
                display: block;
              }
            }
            .miaosha_list_text {
              height: 20px;
              line-height: 20px;
              border-radius: 0 0 5px 5px;
              background-color: #fff;
              padding: 5px;
              .van-cell {
                padding: 0 10px;
                background-color: #fd3029;
                border-radius: 30px;
                height: 20px;
                line-height: 20px;
                color: #fff;
                .van-icon {
                  background-color: #fff;
                  border-radius: 50%;
                  height: 14px;
                  width: 14px;
                  font-size: 10px;
                  color: #e42920;
                  line-height: 14px;
                  text-align: center;
                  margin-top: 3px;
                  margin-right: -7px;
                }
              }
            }
          }
        }
      }
    }
  }
  // 每日爆品
  .meiribaopin {
    padding: 0 10px;
    margin: 10px 0;
    .meiribaopin_main {
      overflow: hidden;
      padding: 10px;
      background-color: #fff;
      border-radius: 10px;
      .van-cell {
        padding: 0;
        .van-cell__title {
          flex: 1;
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 2px;
        }
        .right_icon {
          color: #999;
          line-height: 24px;
          font-size: 12px;
        }
        .van-cell__right-icon {
          font-size: 14px;
          margin-left: 0;
        }
      }
      .meiribaopin_label {
        width: 100%;
        overflow: auto;
        padding-top: 10px;
        .meiribaopin_label_main {
          white-space: nowrap;
          .baopin_list {
            overflow: hidden;
            position: relative;
            margin-right: 10px;
            display: inline-block;
            &:last-of-type {
              margin-right: 0;
            }
            .baopin_list_img {
              position: relative;
              overflow: hidden;
              .van-image {
                width: 100%;
                height: 100%;
                border-radius: 4px;
                overflow: hidden;
              }
            }
            .baopin_list_title {
              .van-cell__title {
                letter-spacing: 0px;
                color: #666;
                font-size: 13px;
              }
            }
            .baopin_list_price {
              .van-cell__title {
                letter-spacing: 0px;
                color: #f6003d;
                line-height: 20px;
                height: 20px;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
  // 新品特价、明日预告
  .mingrixinpin {
    margin-top: 10px;
    padding: 0 10px;
    overflow: hidden;
    .van-cell {
      padding: 0;
      background-color: #fff;
      border-radius: 10px;
      .van-cell__title,
      .van-cell__value {
        flex: 1;
      }
      .xinpintejia {
        .van-cell {
          padding: 10px;
          .van-cell__title {
            text-align: center;
            padding: 0 5px 0 0;
            min-width: 70px;
            overflow: hidden;
            .xinpintejia_text {
              font-size: 16px;
              font-weight: bold;
              color: #333;
              position: relative;
              width: 100%;
              height: 24px;
              line-height: 24px;
            }
            .back_color {
              position: absolute;
              height: 10px;
              border-radius: 30px;
              background-color: #ffe6fa;
              left: 10px;
              bottom: 1px;
              right: 10px;
              display: inline-block;
              z-index: 0;
            }
          }
          .van-cell__value {
            text-align: right;
            font-size: 12px;
            padding: 0;
            .back_color {
              position: absolute;
              height: 10px;
              border-radius: 30px;
              background-color: #f2e6ff;
              left: 10px;
              bottom: 1px;
              right: 10px;
              display: inline-block;
              z-index: 0;
            }
          }
          .van-cell__right-icon {
            margin-left: 0;
            font-size: 12px;
          }
          .xinpintejia_text_word {
            position: absolute;
            left: 0px;
            bottom: 0px;
            right: 0px;
            z-index: 0;
          }
        }
        .xinpintejia_goods_main {
          padding: 0 10px 10px;
          position: relative;
          .van-cell__title {
            padding: 0 10px 0 0;
            overflow: hidden;
          }
          .xinpintejia_goods {
            .xinpintejia_goods_img{
              position: relative;
            }
            .van-image {
              border-radius: 4px;
              overflow: hidden;
              display: block;
            }
            .xinpintejia_goods_text {
              color: #e42920;
              font-size: 14px;
              font-weight: bold;
              text-align: center;
            }
          }
        }
      }
    }
  }
  // 商品列表
  .goods_list {
    overflow: hidden;
    position: relative;
    .van-grid-item__content {
      padding: 0;
      border-radius: 8px;
      overflow: hidden;
      .van-image {
        display: block;
      }
      .goods_text {
        height: 40px;
        line-height: 20px;
        padding: 5px 10px 0;
        font-size: 14px;
        color: #333;
        text-align: left;
      }
      .goods_price {
        padding: 5px 10px 10px;
        font-size: 16px;
        color: #c8241a;
        text-align: left;
        span {
          color: #999;
          font-size: 12px;
        }
      }
    }
  }
  // 分类商品
  .category_goods {
    margin: 0;
    padding: 0;
    position: relative;
    overflow: hidden;
    .category_goods_main {
      margin: 0;
      padding: 0;
      position: relative;
      overflow: hidden;
      .category_goods_list {
        margin: 0;
        padding: 0;
        position: relative;
        overflow: hidden;
        .category_goods_title {
          margin: 10px;
          border-radius: 50px;
          height: 50px;
          line-height: 50px;
          text-align: center;
          font-size: 16px;
          color: #333;
          font-weight: bold;
          position: relative;
          background-color: #fff;
          .category_goods_more {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            padding-right: 10px;
            font-weight: normal;
            display: flex;
            font-size: 12px;
            color: #999;
            justify-items: center;
            align-items: center;
          }
        }
        .category_goods_item {
          padding: 0;
          .van-grid-item__content--center {
            padding: 0;
            background-color: #ededed;
          }
          .category_goods_img {
            position: relative;
            overflow: hidden;
            border-radius: 6px 6px 0 0;
            .van-image {
              display: block;
            }
          }
          .category_goods_text {
            padding: 5px;
            overflow: hidden;
            background-color: #fff;
            border-radius: 0 0 6px 6px;
            font-size: 12px;
            .category_goods_text_row1 {
              color: #e42920;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
  // 品牌列表
  .pinpai_list {
    padding: 10px;
    overflow: hidden;
    margin: 10px 0;
    background-color: #fff;
    .van-cell {
      padding: 10px;
      .van-cell__value {
        flex: 1;
      }
      .van-cell__title {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .van-grid-item__text {
      margin-top: 5px;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
      width: 100%;
      text-align: center;
    }
    .van-swipe__indicator {
      background-color: #ccc;
    }
    .van-swipe__indicator--active {
      background-color: #1989fa;
    }
  }
  // 水印
  .shuiyin{
    padding: 2px;
    background-color:#fff;
    position: absolute;
    left: 0;
    bottom: 0;
    color: #808080;
    opacity: 0.5;
    font-size: 14px;
    line-height: 16px;
  }
  // 弹框广告
  .van-popup {
    .van-popup__content {
      img,
      .van-image {
        display: block;
        width: 100%;
      }
    }
    .van-popup__close-icon--top-right {
      top: 10px;
      right: 10px;
      font-size: 30px;
    }
  }

  // 填写邀请码
  .agent_code {
    padding: 20px;
    overflow: hidden;
    background-color: #fff;
    .van-cell {
      margin-bottom: 10px;
      .van-cell__value {
        flex: 1;
      }
    }
  }

  .postDown {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 50px;
  }
}
</style>
