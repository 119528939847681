import Vue from "vue";
import App from "./App.vue";
import '@vant/touch-emulator';
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import axios from "axios";
import qs from "qs";
import QRCode from 'qrcodejs2';
import Qr from 'qrcode';
import VueLocalStorage from "vue-localstorage";
import vueqrcode from 'vue_qrcodes';
import VueQr from 'vue-qr'
import html2canvas from 'html2canvas'
import VueClipboard from 'vue-clipboard2'


import "./assets/iconfont/iconfont.css"


import {
  Icon,
  Dialog,
  Notify,
  Toast,
  Lazyload,
  Col,
  Row,
  Tag,
  NavBar,
  CountDown,
  Button,
  Divider,
  Form,
  Field,
  Cell,
  Card,
  Image as VanImage,
  List,
  Sidebar,
  SidebarItem,
  Calendar,
  SubmitBar,
  Stepper,
  Radio,
  RadioGroup,
  Checkbox,
  CheckboxGroup,
  Swipe,
  SwipeItem,
  Loading,
  Search,
  Grid,
  GridItem,
  CellGroup,
  Tabbar,
  TabbarItem,
  Sticky,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton,
  Sku,
  Popup,
  Uploader,
  Picker,
  DatetimePicker,
  Area,
  ImagePreview,
  AddressList,
  AddressEdit,
  Empty,
  Tab,
  Tabs,
  Step,
  Steps,
  NoticeBar,
  DropdownMenu,
  DropdownItem,
  ActionSheet,
  PullRefresh,
  Progress,
  Switch
} from "vant";

Vue.use(Icon);
Vue.use(Dialog);
Vue.use(Notify);
Vue.use(Toast);
Vue.use(Lazyload);
Vue.use(Col);
Vue.use(Row);
Vue.use(Tag);
Vue.use(NavBar);
Vue.use(CountDown);
Vue.use(Button);
Vue.use(Divider);
Vue.use(Form);
Vue.use(Field);
Vue.use(Cell);
Vue.use(Card);
Vue.use(VanImage);
Vue.use(List);
Vue.use(Sidebar);
Vue.use(SidebarItem);
Vue.use(Calendar);
Vue.use(SubmitBar);
Vue.use(Stepper);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Loading);
Vue.use(Search);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(CellGroup);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Sticky);
Vue.use(GoodsAction);
Vue.use(GoodsActionButton);
Vue.use(GoodsActionIcon);
Vue.use(Sku);
Vue.use(Popup);
Vue.use(Uploader);
Vue.use(Picker);
Vue.use(DatetimePicker);
Vue.use(Area);
Vue.use(ImagePreview);
Vue.use(AddressList);
Vue.use(AddressEdit);
Vue.use(Empty);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Step);
Vue.use(Steps);
Vue.use(NoticeBar);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(ActionSheet);
Vue.use(PullRefresh);
Vue.use(Progress);
Vue.use(Switch);


Vue.use(VueLocalStorage);
axios.defaults.withCredentials = true;
Vue.prototype.$axios = axios;
Vue.prototype.$qs = qs;
Vue.prototype.QRCode = QRCode;
Vue.prototype.$Qr = Qr;
Vue.prototype.$vueqrcode = vueqrcode;
Vue.prototype.$vueqr = VueQr;
Vue.prototype.$html2canvas = html2canvas;

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard)

Vue.config.productionTip = false;

Vue.config.ignoredElements = [
  'wx-open-launch-weapp'
];

const _hmt = [];
window._hmt = _hmt;
(function() {
  const hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?f9d7a5b65ef2879ffdf8a72e857908f1";
  const s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();

router.beforeEach((to, from, next) => {
  console.log(to, from);
  
  if (_hmt) {
    _hmt.push(['_trackPageview', to.fullPath]);
  }
  if (to.path == "/") {
    next({
      name: "Home", //已经登出,Go登录页
      query: {
        shareid: to.query.shareid,
        merchid: to.query.merchid,
      }
    });
  }else {
    next();
  }

});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
